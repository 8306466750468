import store from '@/store/index';

export const authorizationService = {
   navigateToLogin() {
      window.location.replace('/account/login');
   },
   async isAuthenticated(): Promise<boolean> {
      return await store.dispatch('account/getIsAuthenticated');
   },
   async getAccount() {
      await store.dispatch('account/getAccount');
   },
};
