/**
 * @enum {import('ol/color').Color}
 */
export default {
   /**
   * Green - #00a454
   */
   LINE_1: [0, 164, 84],
   /**
   * Orange - #f28230
   */
   LINE_2: [242, 130, 48],
   /**
   * Yellow - #ffdb2e
   */
   LINE_4: [255, 219, 46],
   /**
   * Blue - #00619b
   */
   LINE_5: [0, 97, 155],
   /**
   * Junction - #666666
   */
   LINE_JUNCTION: [102, 102, 102],
};
