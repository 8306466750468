const config = {
   radius: 800, // In meters
};

/**
 * @return {number}
 */
export function get() {
   return config.radius;
}

/**
 * @param {number} radius Radius to set
 * @return {boolean} Whether the radius was set or not.
 */
export function set(radius) {
   let wasSet = false;
   if (config.radius !== radius) {
      config.radius = radius;
      wasSet = true;
   }
   return wasSet;
}
