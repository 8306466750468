import Property from './Property';

/**
 * @typedef {Object} Line
 * @property {string} [category] Category
 * @property {string} [line_description] Description
 * @property {string} [line_direction] Direction
 * @property {string} [line_direction_name] Direction (i18n)
 * @property {string} [line_public_id] Line id
 */

/**
 * @param {import("ol/Feature").default} feature Feature
 * @return {string} Id of the feature
 */
export function getId(feature) {
   return feature.getId();
}

/**
 * @param {import("ol/Feature").default} feature Feature
 * @return {boolean} Whether the bus stop is accessible or
 *     not. Defaults to `true`.
 */
export function getIsAccessible(feature) {
   return feature.get(Property.IS_ACCESSIBLE) !== false;
}

/**
 * @param {import("ol/Feature").default} feature Feature
 * @return {Array.<Line>} List of bus lines
 */
export function getLines(feature) {
   const lines = feature.get(Property.LINES) || [];
   return lines;
}

/**
 * @param {import("ol/Feature").default} feature Feature
 * @return {string} Name of the feature
 */
export function getName(feature) {
   return feature.get(Property.NAME) || '';
}
/**
 * @param {import("ol/Feature").default} feature Feature
 * @return {string} Name of the feature
 */
export function getCurrentStreet(feature) {
   return feature.get(Property.CURRENT_STREET) || '';
}
/**
 * @param {import("ol/Feature").default} feature Feature
 * @return {string} Name of the feature
 */
export function getIntersectionStreet(feature) {
   return feature.get(Property.INTERSECTION_STREET) || '';
}
/**
 * @param {import("ol/Feature").default} feature Feature
 * @return {number} Distance of the feature
 */
export function getDistance(feature) {
   return (feature.get(Property.DISTANCE));
}
/**
 * @param {import("ol/Feature").default} feature Feature
 * @return {string} Name of the feature
 */
export function getAddress(feature) {
   return feature.get(Property.ADRESS) || '';
}
/**
 * @param {import("ol/Feature").default} feature Feature
 * @return {string} Name of the feature
 */
export function getEta(feature) {
   return feature.get(Property.ETA) || '';
}
/**
 * @param {import("ol/Feature").default} feature Feature
 * @return {string} Name of the feature
 */
export function getType(feature) {
   return feature.get(Property.TYPE) || '';
}
