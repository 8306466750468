import { includes } from 'ol/array';

import i18n from '../../i18n';
import Property from './Property';
import { TypeText as ValueTypeText } from './Value';

/**
 * @param {import("ol/Feature").default} feature Feature
 * @return {string} First metro line of the station, i.e. "1", "2", "4" or "5".
 */
export function getFirstLine(feature) {
   const lines = getLines(feature);
   return lines[0];
}

/**
 * @param {import("ol/Feature").default} feature Feature
 * @return {number} Id of the feature
 */
export function getId(feature) {
   return Number(feature.getId());
}

/**
 * @param {string} lineId Line id
 * @return {string}
 */
export function getLineText(lineId) {
   return i18n(`metro.line.${ValueTypeText[lineId]}`);
}

/**
 * @param {import("ol/Feature").default} feature Feature
 * @return {Array.<string>} List of metro lines, i.e. "1", "2", "4" or "5".
 */
export function getLines(feature) {
   const lines = feature.get(Property.LINES) || [];
   return lines;
}

/**
 * @param {import("ol/Feature").default} feature Feature
 * @return {string} Name of the feature
 */
export function getName(feature) {
   return feature.get(Property.NAME) || '';
}

/**
 * @param {import("ol/Feature").default} feature Feature
 * @param {string} line A metro line, for example "1".
 * @return {boolean} Whether the metro station feature has the given
 *     line or not.
 */
export function hasLine(feature, line) {
   const lines = getLines(feature);
   return includes(lines, line);
}

/**
 * @param {import("ol/Feature").default} feature Feature
 * @return {boolean} Whether the metro station is a terminus or not
 */
export function isTerminus(feature) {
   return !!feature.get(Property.IS_TERMINUS);
}
