// TODO - select interaction allowed elements

import 'ol/ol.css';

import {
   ControlBusPosition,
   ControlProperty,
   ControlProximity,
   ControlProximityDestination,
   ControlProximityVehicle,
} from './control';
import {
   InteractionBusPositionManager,
   InteractionSelect,
   InteractionSelectEventType,
} from './interaction';
import { Tile as LayerTile, Vector as LayerVector } from 'ol/layer';
import {
   busLineStyleFunction,
   busPositionStyleFunction,
   busStopStyleFunction,
   metroLineStyleFunction,
   metroStationStyleFunction,
} from './style';
import { debounce, unlistenAllByKey } from './util';
import {
   getDisplay as featureGetDisplay,
   getSelected as featureGetSelected,
   getType as featureGetType,
   getTypePoint as featureGetTypePoint,
   setOpacity as featureSetOpacity,
   unselectAll as featureUnselectAll,
} from './feature';
import {
   getId as metroStationFeatureGetId,
   hasLine as metroStationFeatureHasLine,
   getFirstLine as metroStationGetFirstLine,
} from './metro/station/feature';

import EventType from 'ol/events/EventType';
import Feature from 'ol/Feature';
import FeatureType from './FeatureType';
import FormatGeoJSON from 'ol/format/GeoJSON';
import Map from 'ol/Map';
import MapBrowserEventType from 'ol/MapBrowserEventType';
import MapEventType from 'ol/MapEventType';
import Point from 'ol/geom/Point';
import PopupManager from './popup/Manager';
import SourceTileCache from './source/TileCache';
import SourceVector from 'ol/source/Vector';
import View from 'ol/View';
import { getId as busStopFeatureGetId } from './bus/stop/feature';
import debug from './debug';
import { defaults as defaultControls } from 'ol/control/util';
import { defaults as defaultInteractions } from 'ol/interaction';
import { fitCenterBetweenTwoPoints } from './view';
import { getChangeEventType } from 'ol/Object';
import i18n from './i18n';
import { listen } from 'ol/events';
import markerStyleFunction from './proximity/markerStyles';
import {
   transform as projTransform,
} from 'ol/proj';
import { set as setProximityRadius } from './proximity/radius';
import { includes as sourceVectorIncludes } from './source/vector';
import viewOptions from './viewOptions';

/**
 * @typedef {import("geojson").FeatureCollection} GeoJSONFeatureCollection
 */

/**
 * @typedef {function({lat: number, lon: number}): void} MapMoveEndListener
 */

/**
 * @typedef {Object} ModeCommonOptions
 * @property {boolean|undefined} [showGeolocateButton] Show the geolocate button
 *     in this mode. Defaults to True
 */

/**
 * @typedef {Object} ModeHomeOptions
 * @property {function(): void|undefined} [bixiRefreshCallback] Callback called
 *     when the bixi layer needs to be refreshed
 * @property {MapMoveEndListener|undefined} mapMoveEndListener
 *     Listener for map change events
 */

/**
 * @typedef {Object} ModeSchedulesMetroLineOptions
 * @property {import("ol").Coordinate} centerLonLat The coordinate
 *     where to recenter the map to.
 * @property {number} metroLineType The type of metro line to set as selected
 * @property {function(string, string=): void} [onMetroStationSelect] Callback
 *     method for metro station feature selection
 */

/**
 * @typedef {Object} ModeSchedulesMetroStationOptions
 * @property {number} metroStationId The id of the metro station to
 *     set as selected.
 * @property {number} metroLineType The type of metro line to set as selected
 * @property {function(string, string=): void} [onMetroStationSelect] Callback
 *     method for metro station feature selection
 */

/**
 * @typedef {Object} ModeSchedulesLineOptions
 * @property {function(string): void} [onBusStopSelect] Callback
 *     method for bus stop feature selection
 */

/**
 * @typedef {Object} ModeSchedulesLineStopOptions
 * @property {function(string): void} [onBusStopSelect] Callback
 *     method for bus stop feature selection
 */

/**
 * @typedef {Object} ModeItineraryOptions
 * @property {GeoJSONFeatureCollection} itinerary GeoJSON feature
 *     collection for the itinerary.
 */

/**
 * @enum {number}
 */
const Mode = {
   NONE: 0,
   HOME: 1, // A.K.A. index
   SCHEDULES_LINE: 2,
   SCHEDULES_LINE_STOP: 3,
   SCHEDULES_METRO_LINE: 4,
   SCHEDULES_METRO_STATION: 5,
   ITINERARY: 6,
};

/**
 * @enum {string}
 */
const TypePoint = {
   Origin: 'Origin',
   Destination: 'Destination',
   Taxi: 'Taxi',
   Minibus: 'Minibus',
   Unknown: 'Unknown',
};

/**
 * @enum {number}
 */
const MaxZoomLevel = {
   IN: 4,
   OUT: 2,
   VehicleTrack: 5,
};

/**
 * @type {number}
 */
const MetroLayersDefaultOpacity = 0.5;

/**
 * @type {number}
 */
const MetroLayersFadedOpacity = 0.25;

/**
 * @type {number}
 */
const MetroLayersOpaqueOpacity = 1;

/**
 * @classdesc
 * The MapBrowser is responsible of being the bridge between Vue and
 * the map.
 */
class MapBrowser {
   constructor() {
      /**
       * @type {Array.<import("ol/events").EventsKey>}
       * @private
       */
      this.listenerKeys_ = [];

      /**
       * @type {number}
       * @private
       */
      this.minCenterChange_ = 100;

      /**
       * @type {?Map}
       * @private
       */
      this.map_ = null;

      /**
       * The mode currently active.
       * @type {number}
       * @private
       */
      this.mode_ = Mode.NONE;

      /**
       * @type {FormatGeoJSON}
       * @private
       */
      this.format_ = new FormatGeoJSON();

      /**
       * @type {Feature}
       * @private
       */
      this.geolocationMarker_ = new Feature({
         geometry: new Point([0, 0]),
         color: '#000',
      });

      /**
       * @type {Feature}
       * @private
       */
      this.currentLocationMarker_ = new Feature({
         geometry: new Point([0, 0]),
         color: '#009ee0',
      });

      // === Sources ===

      /**
       * @type {!SourceVector}
       * @private
       */
      this.bixiSource_ = new SourceVector();

      /**
       * @type {!SourceVector}
       * @private
       */
      this.busLineSource_ = new SourceVector();

      /**
       * @type {!SourceVector}
       * @private
       */
      this.busPositionSource_ = new SourceVector();

      /**
       * @type {!SourceVector}
       * @private
       */
      this.busStopSource_ = new SourceVector();

      /**
       * @type {!SourceVector}
       * @private
       */
      this.busStopProximitySource_ = new SourceVector();

      /**
       * @type {!SourceVector}
       * @private
       */
      this.originProximitySource_ = new SourceVector();
      /**
       * @type {!SourceVector}
       * @private
       */
      this.destinationProximitySource_ = new SourceVector();

      /**
       * @type {!SourceVector}
       * @private
       */
      this.metroLineSource_ = new SourceVector();

      /**
       * @type {!SourceVector}
       * @private
       */
      this.metroStationProximitySource_ = new SourceVector();

      /**
       * @type {!SourceVector}
       * @private
       */
      this.metroStationSource_ = new SourceVector();

      /**
       * @type {!SourceVector}
       * @private
       */
      this.itinerarySource_ = new SourceVector();

      // === Layers ===

      /**
       * @type {!LayerVector}
       * @private
       */
      this.busLineLayer_ = new LayerVector({
         name: 'Bus line',
         renderBuffer: 400,
         source: this.busLineSource_,
         style: busLineStyleFunction,
         updateWhileAnimating: false,
         updateWhileInteracting: true,
      });

      /**
       * @type {!LayerVector}
       * @private
       */
      this.busPositionLayer_ = new LayerVector({
         name: 'Bus position',
         source: this.busPositionSource_,
         style: busPositionStyleFunction,
      });

      /**
       * @type {!LayerVector}
       * @private
       */
      this.busStopLayer_ = new LayerVector({
         name: 'Bus stop',
         renderBuffer: 400,
         source: this.busStopSource_,
         style: busStopStyleFunction,
         updateWhileAnimating: false,
         updateWhileInteracting: true,
      });

      /**
       * @type {!LayerVector}
       * @private
       */
      this.busStopProximityLayer_ = new LayerVector({
         name: 'Bus stop proximity',
         source: this.busStopProximitySource_,
         style: busStopStyleFunction,
      });

      /**
       * @type {!LayerVector}
       * @private
       */
      this.originProximityLayer_ = new LayerVector({
         name: 'Origin proximity',
         source: this.originProximitySource_,
         style: busStopStyleFunction,
      });

      /**
       * @type {!LayerVector}
       * @private
       */
      this.destinationProximityLayer_ = new LayerVector({
         name: 'Destination proximity',
         source: this.destinationProximitySource_,
         style: busStopStyleFunction,
      });

      /**
       * @type {!LayerVector}
       * @private
       */
      this.metroLineLayer_ = new LayerVector({
         opacity: MetroLayersDefaultOpacity,
         name: 'Metro line',
         renderBuffer: 400,
         source: this.metroLineSource_,
         style: metroLineStyleFunction,
         updateWhileAnimating: false,
         updateWhileInteracting: true,
      });

      /**
       * @type {!LayerVector}
       * @private
       */
      this.metroStationProximityLayer_ = new LayerVector({
         name: 'Metro station proximity',
         source: this.metroStationProximitySource_,
         style: metroStationStyleFunction,
      });

      /**
       * @type {!LayerVector}
       * @private
       */
      this.metroStationLayer_ = new LayerVector({
         opacity: MetroLayersDefaultOpacity,
         name: 'Metro station',
         renderBuffer: 400,
         source: this.metroStationSource_,
         style: metroStationStyleFunction,
         updateWhileAnimating: false,
         updateWhileInteracting: true,
      });

      /**
       * "My position" marker and circle.
       * @type {!LayerVector}
       * @private
       */
      this.markerLayer_ = new LayerVector({
         name: 'Markers',
         style: markerStyleFunction,
         source: new SourceVector({
            features: [this.currentLocationMarker_, this.geolocationMarker_],
         }),
      });

      // Popup manager

      /**
       * @type {!PopupManager}
       * @private
       */
      this.popupManager_ = new PopupManager({
         bixiSource: this.bixiSource_,
         busPositionSource: this.busPositionSource_,
         busStopProximitySource: this.busStopProximitySource_,
         originProximitySource: this.originProximitySource_,
         destinationProximitySource: this.destinationProximitySource_,
         metroStationProximitySource: this.metroStationProximitySource_,
      });

      // busPosition - Manager

      /**
       * @type {!InteractionBusPositionManager}
       * @private
       */
      this.busPositionManagerInteraction_ = new InteractionBusPositionManager({
         busLineSource: this.busLineSource_,
         busPositionSource: this.busPositionSource_,
      });

      // Select interaction and properties related to selection

      /**
       * @type {!InteractionSelect}
       * @private
       */
      this.selectInteraction_ = new InteractionSelect({
         layers: [
            this.busStopLayer_,
            this.busPositionLayer_,
            this.busStopProximityLayer_,
            this.originProximityLayer_,
            this.destinationProximityLayer_,
            this.metroStationProximityLayer_,
            this.metroStationLayer_,
         ],
         popupManager: this.popupManager_,
         allowedElements: [
            /* toolsControl.getElement() */
         ],
      });

      /**
       * Id of the metro station feature to select and recenter the map
       * to upon loading metro station features.
       * @type {?number}
       * @private
       */
      this.selectedMetroLineType_ = null;

      /**
       * Id of the metro station feature to select and recenter the map
       * to upon loading metro station features.
       * @type {?number}
       * @private
       */
      this.selectedMetroStationId_ = null;

      // Controls
      /**
       * @type {!ControlBusPosition}
       * @private
       */
      this.busPositionControl_ = new ControlBusPosition({
         layer: this.busPositionLayer_,
      });

      /**
       * @type {!ControlProximity}
       * @private
       */
      this.proximityControl_ = new ControlProximity({});

      /**
       * @type {!ControlProximityVehicle}
       * @private
       */
      this.proximityControlBus_ = new ControlProximityVehicle({
         type: TypePoint.Minibus,
      });

      /**
      * @type {!ControlProximityVehicle}
      * @private
      */
      this.proximityControlTaxi_ = new ControlProximityVehicle({
         type: TypePoint.Taxi,
      });

      /**
       * @type {!ControlProximityDestination}
       * @private
       */
      this.proximityControlDestination_ = new ControlProximityDestination({});

      /**
       * @type {import("./util").Debounced<MapMoveEndListener>}
       * @private
       */
      this.mapMoveEndListener_ = null;

      /**
       * @type {?function(string): void}
       * @private
       */
      this.onBusStopSelect_ = null;

      /**
       * @type {?function(string, string=): void}
       * @private
       */
      this.onMetroStationSelect_ = null;

      /**
       * @type {?function(): void|undefined}
       * @private
       */
      this.bixiRefreshCallback_ = null;

      /**
       * @type {?function(): void|undefined}
       * @private
       */
      this.geolocationRefreshCallback_ = null;

      /**
       * @type {?function(): void|undefined}
       * @private
       */
      this.geolocationBusRefreshCallback_ = null;

      /**
       * @type {?function(): void|undefined}
       * @private
       */
      this.geolocationPopUpBusRefreshCallback_ = null;

      /**
       * @type {?function(): void|undefined}
       * @private
       */
      this.geolocationOriginRefreshCallback_ = null;

      /**
       * @type {?function(): void|undefined}
       * @private
       */
      this.geolocationDestinationRefreshCallback_ = null;

      /**
       * @type {import("ol").Coordinate}
       * @private
       */
      this.lastReportedCenter_ = [0, 0];

      /**
       * @type {Boolean}
       * @private
       */
      this.geolocating_ = false;

      /**
       * @type {Boolean}
       * @private
       */
      this.enableSetCenterByGeolocation_ = false;

      /*
      *number of time there is no bus positions when addBusPositions() is triggered.
      */
      this.numOfTimeNoBusPositions = 0;
   }

   /**
    * Clear all features related to bus:
    * - lines
    * - stops
    * - position
    */
   clearBusFeatures() {
      this.busLineSource_.clear();
      this.busStopSource_.clear();
      this.busPositionSource_.clear();
      this.numOfTimeNoBusPositions = 0;
   }

   /**
 * @param {string} data.
 */
   recenterTwoPoints(feature1, feature2) {
      const featuresVehicule = feature1 ? this.format_.readFeatures(feature1) : null;
      const featuresOriginOrDestination = feature2 ? this.format_.readFeatures(feature2) : null;
      if (featuresVehicule) {
         fitCenterBetweenTwoPoints({
            featurePoint1: featuresVehicule,
            featurePoint2: featuresOriginOrDestination,
            map: this.map,
         });
      }
   }

   /**
    * @param {GeoJSONFeatureCollection} bixis
    */
   setBixis(bixis) {
      const bixiSource = this.bixiSource_;
      bixiSource.clear();
      const features = this.format_.readFeatures(bixis);
      bixiSource.addFeatures(features);
   }

   /**
    * Clear bus position features, then add new ones
    *
    * @param {GeoJSONFeatureCollection} busPositions GeoJSON feature
    *     collection of bus positions to set
    */
   setBusPositions(busPositions) {
      if (busPositions) {
         const busPositionSource = this.busPositionSource_;
         busPositionSource.clear();
         this.addBusPositions(busPositions);
      }
   }

   noBusPositionsToAdd() {
      this.numOfTimeNoBusPositions += 1;

      if (this.numOfTimeNoBusPositions === 3) {
         this.numOfTimeNoBusPositions = 0;
         this.busPositionSource_.clear();
      }
   }

   /**
    * Add new bus position features, unless there are no bus line features.
    *
    * @param {GeoJSONFeatureCollection} busPositions GeoJSON feature
    *     collection of bus positions to add
    */
   addBusPositions(busPositions) {
      // No need to do anything if there aren't any bus line features (yet)
      if (!busPositions || !busPositions.features) {
         this.noBusPositionsToAdd();
         return;
      }

      // if (!lineFeatures.length || lineFeatures.length === 0) {
      //   return
      // }
      const features = this.format_.readFeatures(busPositions);

      // // No need to do anything if there are no bus position features to add
      // if (!features || !features.length || features.length === 0) {
      //   this.noBusPositionsToAdd()
      //   return
      // }

      // // Validate one of the bus position feature 'route id', which must
      // // match one among the bus line features
      // const geom = busPositionFeatureGetBusLineGeometry(
      //   features[0],
      //   busLineSource
      // )

      // if (!geom || geom.length === 0) {
      //   return
      // }

      if (debug.enabled) {
         console.log('MapBrowser#addBusPositions: ', features);
      }

      this.numOfTimeNoBusPositions = 0;
      if (features) this.busPositionManagerInteraction_.addFeatures(features);
   }

   /**
    * @param {GeoJSONFeatureCollection} geojson GeoJSON feature
    *     collection of metro stations and metro lines.
    */
   setMetro(geojson) {
      const metroLineSource = this.metroLineSource_;
      const metroStationSource = this.metroStationSource_;

      // (1) Clear
      metroLineSource.clear();
      metroStationSource.clear();

      // (2) Read
      const features = this.format_.readFeatures(geojson);
      const metroLineFeatures = [];
      const metroStationFeatures = [];

      // (3) Populate
      for (const feature of features) {
         const type = featureGetType(feature);
         switch (type) {
            case FeatureType.METRO_LINE_1:
            case FeatureType.METRO_LINE_2:
            case FeatureType.METRO_LINE_4:
            case FeatureType.METRO_LINE_5:
               metroLineFeatures.push(feature);
               break;

            case FeatureType.METRO_STATION_1:
            case FeatureType.METRO_STATION_2:
            case FeatureType.METRO_STATION_4:
            case FeatureType.METRO_STATION_5:
            case FeatureType.METRO_STATION_JUNCTION:
               metroStationFeatures.push(feature);
               break;

            default:
               console.error(`Invalid feature type: ${type}`);
               break;
         }
      }

      // (4) Add
      metroLineSource.addFeatures(metroLineFeatures);
      metroStationSource.addFeatures(metroStationFeatures);

      // (5) If a metro line type is selected, then set the opacity of
      //     all the metro features (lines, stations) that are not
      //     associated with the selected line as transparent.
      if (this.selectedMetroLineType_) {
         for (const metroLineFeature of metroLineFeatures) {
            const metroLineType = featureGetType(metroLineFeature);
            if (metroLineType !== this.selectedMetroLineType_) {
               featureSetOpacity(metroLineFeature, MetroLayersFadedOpacity);
            }
         }
         const selectedMetroLineId = this.selectedMetroLineType_.toString();
         for (const metroStatFeat of metroStationFeatures) {
            if (!metroStationFeatureHasLine(metroStatFeat, selectedMetroLineId)) {
               featureSetOpacity(metroStatFeat, MetroLayersDefaultOpacity);
            }
         }
      }
   }

   /**
    * @param {number} radius Radius to set for the proximity feature
    */
   setProximityRadius(radius) {
      const wasSet = setProximityRadius(radius);
      if (wasSet) {
         // For the layer to reload
         this.markerLayer_.changed();
      }
   }

   /**
    * @param {GeoJSONFeatureCollection} destination
    */
   setProximityDestination(destination) {
      this.setProximityItem(destination, this.destinationProximitySource_);
   }

   /**
    * @param {GeoJSONFeatureCollection} origin
    */
   setProximityOrigin(origin) {
      this.setProximityItem(origin, this.originProximitySource_);
   }

   /**
    * @param {GeoJSONFeatureCollection} stops
    */
   setProximityBus(bus) {
      this.setProximityItem(bus, this.busStopProximitySource_);
   }

   setProximityItem(item, layer) {
      if (!item) {
         return this.clearLayer(layer);
      }
      // Close popup if a 'proximity' feature is currently selected,
      // i.e.  if it's something else, like a bixi popup, leave it
      // opened

      const features = this.format_.readFeatures(item);
      const hasPopUp = this.layerHasPopUp(layer);
      layer.clear();

      const display = featureGetDisplay(features[0]);
      if (display) {
         layer.addFeatures(features);
      }
      if (hasPopUp) {
         this.popupManager_.createAndShowPopup(features[0]);
      }
   }

   layerHasPopUp(layer) {
      const popupFeature = this.popupManager_.getPopupFeature();
      return popupFeature && sourceVectorIncludes(layer, popupFeature);
   }

   clearLayer(layer) {
      if (this.layerHasPopUp(layer)) {
         this.popupManager_.closePopup();
      }
      layer.clear();
   }
   /**
   * @param {import("ol").Coordinates} center Center
   * @param {import("ol").ProjectionLike} proj Center projection
   * @param {boolean} setCenter Set the map
   */

   setGeolocation(center, proj = 'EPSG:4326', setCenter = true) {
      if (!this.enableSetCenterByGeolocation_) {
         return;
      }

      const view = this.map.getView();
      const toProj = view.getProjection();
      const coordinates = projTransform(center, proj, toProj);

      if (setCenter) {
         this.geolocating_ = true;
         view.setCenter(coordinates);
      }

      this.currentLocationMarker_.getGeometry().setCoordinates([0, 0]);
      this.geolocationMarker_.getGeometry().setCoordinates(coordinates);
      if (this.mode_ === Mode.HOME) {
         this.currentLocationMarker_.set('active', false);
         this.geolocationMarker_.set('active', true);
      }
   }

   /**
    * @param {boolean} type Set status
    */
   disableAllButtons(status) {
      this.proximityControlTaxi_.disable(status);
      this.proximityControlBus_.disable(status);
      this.proximityControlDestination_.disable(status);
   }

   /**
    * @param {boolean} type Set status
    */
   setStatusOriginDestinationControl(status) {
      if (status) {
         this.proximityControlTaxi_.setStatus(false);
         this.proximityControlBus_.setStatus(false);
      }
      this.proximityControlDestination_.setStatus(status);
   }

   /**
   * @param {boolean} type Set status
   */
   setStatusVehicleControl(status) {
      if (status) {
         this.proximityControlDestination_.setStatus(false);
      }
      this.proximityControlTaxi_.setStatus(status);
      this.proximityControlBus_.setStatus(status);
   }

   /**
    * @param {string} type Set the map
    */
   addVehicleControl(type) {
      const map = this.map;
      this.proximityControlTaxi_.setStatus(false);
      this.proximityControlBus_.setStatus(false);
      map.removeControl(this.proximityControlTaxi_);
      map.removeControl(this.proximityControlBus_);

      if (type === TypePoint.Taxi || type === TypePoint.Unknown) {
         map.addControl(this.proximityControlTaxi_);
      } else {
         map.addControl(this.proximityControlBus_);
      }
   }

   /**
    * @param {number} minCenterChange Minimum center change to trigger a center
    * change, in meters
    */
   setMinCenterChange(minCenterChange) {
      this.minCenterChange_ = minCenterChange;
   }

   /**
    * @param {HTMLElement|string} target Target
    */
   setTarget(target) {
      if (typeof target === 'string') {
         target = document.getElementById(target);
      }
      this.map.setTarget(target);
   }

   /**
    * @param {?function():void} geolocationHandler Function to call when the
    *     geolocate button is pressed
    */
   setGeolocationHandler(geolocationHandler) {
      this.geolocationRefreshCallback_ = geolocationHandler;
   }

   /**
    * @param {?function():void} geolocationHandler Function to call when the
    *     geolocate button is pressed
    */
   setGeolocationPopUpBusHandler(geolocationHandler) {
      this.geolocationPopUpBusRefreshCallback_ = geolocationHandler;
   }

   /**
    * @param {?function():void} geolocationHandler Function to call when the
    *     geolocate button is pressed
    */
   setGeolocationBusHandler(geolocationHandler) {
      // console.log('geolocationHandler')
      this.geolocationBusRefreshCallback_ = geolocationHandler;
   }

   /**
    * @param {?function():void} geolocationHandler Function to call when the
    *     geolocate button is pressed
    */
   setGeolocationOriginHandler(geolocationHandler) {
      this.geolocationOriginRefreshCallback_ = geolocationHandler;
   }

   /**
    * @param {?function():void} geolocationHandler Function to call when the
    *     geolocate button is pressed
    */
   setGeolocationDestinationHandler(geolocationHandler) {
      this.geolocationDestinationRefreshCallback_ = geolocationHandler;
   }

   /**
    * @param {import("ol/MapEvent").devault} evt Event
    * @private
    */
   handleMapMoveEnd_() {
      const popupFeature = this.popupManager_.getPopupFeature();
      if (popupFeature) {
         const type = featureGetTypePoint(popupFeature);
         if (type === TypePoint.Taxi || type === TypePoint.Minibus) {
            this.popupManager_.createAndShowPopup(popupFeature);
         }
      }
   }

   /**
    * @param {import("ol/MapEvent").devault} evt Event
    * @private
    */
   handleMapManualDragZoom_() {
      this.setStatusOriginDestinationControl(false);
      this.setStatusVehicleControl(false);

      if (!this.mapMoveEndListener_) {
         return;
      }

      this.mapMoveEndListener_();
   }

   /**
    * @param {import("ol/events.js").ChangeEvent} event
    * @private
    */
   onZoomChange_(event) {
      if (this.mapMoveEndListener_) {
         this.mapMoveEndListener_.cancel();
      }
      console.log(event);
   }

   /**
    * @return {!Map} OpenLayers Map object
    */
   get map() {
      if (this.map_ === null) {
         const view = new View(viewOptions);
         this.map_ = new Map({
            controls: defaultControls({
               attribution: false,
               zoom: false,
            }),
            interactions: defaultInteractions({
               altShiftDragRotate: false,
               pinchRotate: false,
            }),
            layers: [
               new LayerTile({
                  source: new SourceTileCache({
                     attributions: [i18n('map.attribution')],
                     url: 'https://www.stm.info',
                     layer: 'maps',
                     extension: '.png',
                  }),
               }),
            ],
            loadTilesWhileAnimating: true,
            loadTilesWhileInteracting: true,
            view: view,
         });
         // movestart POINTERDRAG pointerdrag
         listen(this.map_, MapBrowserEventType.POINTERDRAG, this.handleMapManualDragZoom_, this);
         listen(this.map_, EventType.WHEEL, this.handleMapManualDragZoom_, this);
         listen(this.map_, MapBrowserEventType.DBLCLICK, this.handleMapManualDragZoom_, this);
         listen(this.map_, MapEventType.MOVEEND, this.handleMapMoveEnd_, this);

         if (debug.enabled) {
            console.log('MapBrowser: ', this);
         }
      }

      return this.map_;
   }

   // === Mode COMMON (is called for all modes) ===
   /**
    * @param {ModeCommonOptions} options Options.
    * @private
    */
   modeEnableCommon_(options) {
      if (debug.enabled) {
         console.log('modeEnableCommon');
         console.log(options);
      }

      const map = this.map;
      map.addInteraction(this.popupManager_);
      map.addInteraction(this.selectInteraction_);

      map.addControl(this.proximityControlDestination_);

      this.listenerKeys_.push(
         listen(
            this.proximityControlTaxi_,
            getChangeEventType(ControlProperty.ACTIVITY),
            this.handleProximityBusControlActivityChange_,
            this,
         ),
      );

      this.listenerKeys_.push(
         listen(
            this.proximityControlBus_,
            getChangeEventType(ControlProperty.ACTIVITY),
            this.handleProximityBusControlActivityChange_,
            this,
         ),
      );

      this.listenerKeys_.push(
         listen(
            this.proximityControlDestination_,
            getChangeEventType(ControlProperty.ACTIVITY),
            this.handleProximityDestinationControlActivityChange_,
            this,
         ),
      );

      this.listenerKeys_.push(
         listen(
            this.selectInteraction_,
            InteractionSelectEventType.CLICK,
            this.handleSelectInteractionClick_,
            this,
         ),
      );
   }

   /**
    * @private
    */
   modeDisableCommon_() {
      if (debug.enabled) {
         console.log('modeDisableCommon');
      }

      const map = this.map;

      map.removeInteraction(this.popupManager_);
      map.removeInteraction(this.selectInteraction_);

      map.removeControl(this.proximityControlDestination_);

      this.clearBusFeatures();

      this.metroLineLayer_.setOpacity(MetroLayersDefaultOpacity);
      this.metroStationLayer_.setOpacity(MetroLayersDefaultOpacity);

      unlistenAllByKey(this.listenerKeys_);
   }

   // === Mode HOME ===

   /**
    * @param {ModeHomeOptions} options Options.
    */
   enablemapMoveEndListener(options) {
      if (options.mapMoveEndListener) {
         this.mapMoveEndListener_ = debounce(options.mapMoveEndListener, 200);
      }
   }

   /**
    * @param {ModeHomeOptions} options Options.
    */
   modeEnableHome(options) {
      this.modeDisable_();
      this.modeEnableCommon_(/** @type {ModeCommonOptions} */(options));

      if (debug.enabled) {
         console.log('modeEnableHome');
      }
      this.enableSetCenterByGeolocation_ = true;
      this.currentLocationMarker_.set('hidden', false);
      this.geolocationMarker_.set('active', true);

      const map = this.map;

      map.getView().setZoom(MaxZoomLevel.IN);

      map.addLayer(this.metroLineLayer_);
      map.addLayer(this.metroStationLayer_);
      map.addLayer(this.busStopProximityLayer_);
      map.addLayer(this.originProximityLayer_);
      map.addLayer(this.destinationProximityLayer_);
      map.addLayer(this.metroStationProximityLayer_);
      // map.addLayer(this.markerLayer_)
      map.addLayer(this.busPositionLayer_);
      // map.addInteraction(this.busPositionManagerInteraction_)

      this.mode_ = Mode.SCHEDULES_LINE;

      // if (options.mapMoveEndListener) {
      //    this.mapMoveEndListener_ = debounce(options.mapMoveEndListener, 200);
      // }
   }

   /**
    * @private
    */
   modeDisableHome_() {
      if (debug.enabled) {
         console.log('modeDisableHome');
      }
      this.enableSetCenterByGeolocation_ = false;
      this.mapMoveEndListener = null;

      this.currentLocationMarker_.set('hidden', true);
      this.geolocationMarker_.set('active', false);

      const map = this.map;
      this.bixiRefreshCallback_ = null;

      map.removeLayer(this.metroLineLayer_);
      map.removeLayer(this.metroStationLayer_);
      map.removeLayer(this.busStopProximityLayer_);
      map.removeLayer(this.originProximityLayer_);
      map.removeLayer(this.destinationProximityLayer_);
      map.removeLayer(this.metroStationProximityLayer_);
      map.removeLayer(this.bixiLayer_);
      map.removeLayer(this.markerLayer_);
   }

   // === Mode SCHEDULES_LINE ===

   /**
    * @param {ModeSchedulesLineOptions} options Options.
    */
   modeEnableSchedulesLine(options) {
      this.modeDisable_();
      this.modeEnableCommon_(/** @type {ModeCommonOptions} */(options));
      if (debug.enabled) {
         console.log('modeEnableSchedulesLine');
      }
      console.log(options);
      const map = this.map;

      // map.addLayer(this.metroLineLayer_);
      // map.addLayer(this.metroStationLayer_);
      map.addLayer(this.busLineLayer_);
      map.addLayer(this.busStopLayer_);
      map.addLayer(this.busPositionLayer_);
      map.addLayer(this.markerLayer_);

      map.addInteraction(this.busPositionManagerInteraction_);

      map.addControl(this.busPositionControl_);

      // this.onBusStopSelect_ = options.onBusStopSelect;

      this.mode_ = Mode.SCHEDULES_LINE;
   }

   /**
    * @private
    */
   modeDisableSchedulesLine_() {
      if (debug.enabled) {
         console.log('modeDisableSchedulesLine');
      }

      const map = this.map;

      map.removeLayer(this.metroLineLayer_);
      map.removeLayer(this.metroStationLayer_);
      map.removeLayer(this.busLineLayer_);
      map.removeLayer(this.busStopLayer_);
      map.removeLayer(this.busPositionLayer_);
      map.removeLayer(this.markerLayer_);

      map.removeInteraction(this.busPositionManagerInteraction_);

      map.removeControl(this.busPositionControl_);

      this.onBusStopSelect_ = null;
   }

   // === Mode SCHEDULES_LINE_STOP ===

   /**
    * @param {ModeSchedulesLineStopOptions} options Options.
    */
   modeEnableSchedulesLineStop(options) {
      this.modeDisable_();
      this.modeEnableCommon_(/** @type {ModeCommonOptions} */(options));

      if (debug.enabled) {
         console.log('modeEnableSchedulesLineStop');
      }

      const map = this.map;

      map.addLayer(this.metroLineLayer_);
      map.addLayer(this.metroStationLayer_);
      map.addLayer(this.busLineLayer_);
      map.addLayer(this.busStopLayer_);
      map.addLayer(this.busPositionLayer_);
      map.addLayer(this.markerLayer_);

      map.addInteraction(this.busPositionManagerInteraction_);

      map.addControl(this.busPositionControl_);

      this.onBusStopSelect_ = options.onBusStopSelect;

      featureUnselectAll(this.busStopSource_.getFeatures());

      this.mode_ = Mode.SCHEDULES_LINE_STOP;
   }

   /**
    * @private
    */
   modeDisableSchedulesLineStop_() {
      if (debug.enabled) {
         console.log('modeDisableSchedulesLineStop');
      }

      const map = this.map;

      map.removeLayer(this.metroLineLayer_);
      map.removeLayer(this.metroStationLayer_);
      map.removeLayer(this.busLineLayer_);
      map.removeLayer(this.busStopLayer_);
      map.removeLayer(this.busPositionLayer_);
      map.removeLayer(this.markerLayer_);

      map.removeInteraction(this.busPositionManagerInteraction_);

      map.removeControl(this.busPositionControl_);

      this.onBusStopSelect_ = null;
   }

   // === Mode SCHEDULES_METRO_LINE ===

   /**
    * @private
    */
   modeDisableSchedulesMetroLine_() {
      if (debug.enabled) {
         console.log('modeDisableSchedulesMetroLine');
      }

      const map = this.map;

      map.removeLayer(this.metroLineLayer_);
      map.removeLayer(this.metroStationLayer_);
      map.removeLayer(this.markerLayer_);

      this.selectedMetroLineType_ = null;
      this.onMetroStationSelect_ = null;
   }

   // === Mode SCHEDULES_METRO_STATION ===

   /**
    * @param {ModeSchedulesMetroStationOptions} options Options.
    */
   modeEnableSchedulesMetroStation(options) {
      this.modeDisable_();
      this.modeEnableCommon_(/** @type {ModeCommonOptions} */(options));

      if (debug.enabled) {
         console.log('modeEnableSchedulesMetroStation');
      }

      const map = this.map;

      map.addLayer(this.metroLineLayer_);
      map.addLayer(this.metroStationLayer_);
      map.addLayer(this.markerLayer_);

      this.metroLineLayer_.setOpacity(MetroLayersOpaqueOpacity);
      this.metroStationLayer_.setOpacity(MetroLayersOpaqueOpacity);

      this.selectedMetroLineType_ = options.metroLineType;
      this.selectedMetroStationId_ = options.metroStationId;
      this.onMetroStationSelect_ = options.onMetroStationSelect;

      this.mode_ = Mode.SCHEDULES_METRO_STATION;
   }

   /**
    * @private
    */
   modeDisableSchedulesMetroStation_() {
      if (debug.enabled) {
         console.log('modeDisableSchedulesMetroStation');
      }

      const map = this.map;

      map.removeLayer(this.metroLineLayer_);
      map.removeLayer(this.metroStationLayer_);
      map.removeLayer(this.markerLayer_);

      this.selectedMetroLineType_ = null;
      this.selectedMetroStationId_ = null;
      this.onMetroStationSelect_ = null;
   }

   // === Mode ITINERARY ===

   /**
    * @param {ModeItineraryOptions} options Options.
    */
   modeEnableItinerary(options) {
      this.modeDisable_();
      this.modeEnableCommon_(/** @type {ModeCommonOptions} */(options));

      if (debug.enabled) {
         console.log('modeEnableItinerary');
      }

      const map = this.map;

      map.addLayer(this.markerLayer_);
      map.addLayer(this.metroLineLayer_);
      map.addLayer(this.metroStationLayer_);

      this.metroLineLayer_.setOpacity(MetroLayersFadedOpacity);
      this.metroStationLayer_.setOpacity(MetroLayersFadedOpacity);

      const itinerary = options.itinerary;
      const itineraryFeatures = this.format_.readFeatures(itinerary);
      this.itinerarySource_.addFeatures(itineraryFeatures);

      const extent = this.itinerarySource_.getExtent();
      map.getView().fit(extent);

      this.mode_ = Mode.ITINERARY;
   }

   /**
    * @private
    */
   modeDisableItinerary_() {
      if (debug.enabled) {
         console.log('modeDisableItinerary');
      }

      const map = this.map;

      this.itinerarySource_.clear();

      map.removeLayer(this.markerLayer_);
      map.removeLayer(this.metroLineLayer_);
      map.removeLayer(this.metroStationLayer_);
   }

   // === Mode disable ===

   /**
    * @private
    */
   modeDisable_() {
      // No mode active? Nothing to do...
      if (!this.mode_) {
         return;
      }

      switch (this.mode_) {
         case Mode.SCHEDULES_LINE:
            this.modeDisableSchedulesLine_();
            break;
         case Mode.SCHEDULES_LINE_STOP:
            this.modeDisableSchedulesLineStop_();
            break;
         case Mode.SCHEDULES_METRO_LINE:
            this.modeDisableSchedulesMetroLine_();
            break;
         case Mode.SCHEDULES_METRO_STATION:
            this.modeDisableSchedulesMetroStation_();
            break;
         case Mode.HOME:
            this.modeDisableHome_();
            break;
         case Mode.ITINERARY:
            this.modeDisableItinerary_();
            break;
         default:
            break;
      }

      this.modeDisableCommon_();
      this.mode_ = Mode.NONE;
   }

   // === Handlers ===

   handleProximityControlActivityChange_() {
      if (this.geolocationRefreshCallback_) {
         this.geolocationRefreshCallback_();
      }
   }

   handleProximityDestinationControlActivityChange_() {
      if (this.geolocationDestinationRefreshCallback_) {
         this.geolocationDestinationRefreshCallback_();
      }
   }

   handleProximityOriginControlActivityChange_() {
      if (this.geolocationOriginRefreshCallback_) {
         this.geolocationOriginRefreshCallback_();
      }
   }

   handleProximityBusControlActivityChange_() {
      if (this.geolocationBusRefreshCallback_) {
         this.geolocationBusRefreshCallback_();
      }
   }

   handleProximityPopUpBusControlActivityChange_() {
      if (this.geolocationPopUpBusRefreshCallback_) {
         this.geolocationPopUpBusRefreshCallback_();
      }
   }

   /**
    * @param {InteractionSelectEvent} evt Event
    * @private
    */
   handleSelectInteractionClick_(evt) {
      const feature = evt.feature;

      // If user clicked on a bus stop or metro station, then go to that
      // stop page (unless the feature is already selected)
      if (sourceVectorIncludes(this.busStopSource_, feature)) {
         if (!featureGetSelected(feature) && this.onBusStopSelect_) {
            const busStopId = busStopFeatureGetId(feature);
            this.onBusStopSelect_(`${busStopId}`);
         }
      } else if (sourceVectorIncludes(this.metroStationSource_, feature)) {
         if (!featureGetSelected(feature) && this.onMetroStationSelect_) {
            const metroStationId = metroStationFeatureGetId(feature);

            // If the selected metro station feature is not on the same
            // line has the currently selected metro line, then we need to
            // change line as well. Pick the first one.
            const selectedMetroLineId = this.selectedMetroLineType_.toString();
            let metroLineId;
            if (!metroStationFeatureHasLine(feature, selectedMetroLineId)) {
               metroLineId = metroStationGetFirstLine(feature);
            }

            this.onMetroStationSelect_(`${metroStationId}`, metroLineId);
         }
      }
   }
}

export default new MapBrowser();
