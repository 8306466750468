/**
 * @param {import("ol/coordinate").Coordinate} c1 first coordinate
 * @param {import("ol/coordinate").Coordinate} c2 second coordinate
 * @return {boolean} Whether the coordinates are equal or not.
 */
export function equal(c1, c2) {
   return c1[0] === c2[0] && c1[1] === c2[1];
}

/**
 * @param {string} str Comma-separated coordinate string.
 * @return {import("ol/coordinate").Coordinate} Coordinate
 */
export function fromString(str) {
   return str.split(',').map(function (strNum) {
      return Number(strNum.trim());
   });
}
