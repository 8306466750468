import axios, {
   AxiosError,
   AxiosInstance,
   AxiosRequestConfig,
   AxiosResponse,
} from 'axios';

import store from '@/store/index';
import { authorizationService } from '@/services/authorizationService';

export const ApiService: AxiosInstance = axios.create(<AxiosRequestConfig>{
   baseURL: process.env.VUE_APP_API_BASE_URL,
   headers: {
      'Cache-control': 'no-cache no-store',
      Pragma: 'no-cache',
      Expires: '0',
      Accept: 'application/json',
      'Content-type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Headers': 'Content-Type, X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Date, X-Api-Version, X-File-Name',
      'Access-Control-Allow-Methods': 'POST,GET,PUT,PATCH,DELETE,OPTIONS',
   },
});

const interceptor = async (error: AxiosError) => {
   errorHandler(error);

   return Promise.reject(error);
};

const errorHandler = (error: AxiosError<any>) => {
   if (error && error.response) {
      const errorCode = error.response.status.toString();
      if (errorCode !== '400' && errorCode !== '404' && !error.response.config.headers['X-NoRouter']) {
         store.dispatch('setServiceError', error.response.data.message);
      } else {
         if (error.response.headers['content-type'].includes('application/problem+json')) {
            error.message = error.response.data?.detail ?? error.response.data?.title;
         } else {
            error.message = error.response.data.message;
         }
      }
   }
};

const authenticationGuard = async (config: AxiosRequestConfig) => {
   if (config.url && config.url.includes('isauthenticated')) {
      return config;
   }

   const isAuthenticated = await authorizationService.isAuthenticated();

   if (!isAuthenticated) {
      authorizationService.navigateToLogin();
      throw new axios.Cancel('Unauthorized user.');
   }

   return Promise.resolve(config);
};

const cultureInterceptor = async (config: AxiosRequestConfig) => {
   if (config.url && config.url.includes('account')) {
      return config;
   }

   config.url = `/${store.getters.locale}/${config.url}`;

   return Promise.resolve(config);
};

const clearServiceErrorInterceptor = (response: AxiosResponse<any>) => {
   if (!(response.config.url && response.config.url.includes('account'))) {
      store.dispatch('clearServiceError');
   }

   return response;
};

ApiService.interceptors.request.use(config => authenticationGuard(config), error => error);
ApiService.interceptors.request.use(config => cultureInterceptor(config), error => error);
ApiService.interceptors.response.use(response => clearServiceErrorInterceptor(response), error => interceptor(error));
