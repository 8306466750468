/**
 * @enum {string}
 */
export default {
   // Native properties
   NEXT_STOP_NAME: 'a',
   IS_CONGESTION: 'b',
   IS_REAL: 'c',
   IS_PLANIFIED: 'd',
   IS_AT_STOP: 'e',
   JOURNEY_REF: 'f',
   IS_LAST: 'g',
   NEXT_STOP_IDENTIFIER: 'h',
   IS_CANCELLED: 'i',
   IS_RAMP_CANCELLED: 'j',
   NEXT_STOP_TIME: 'l',
   LAST_STOP: 'm',
   TRACE: 'n',
   BUS_LINE_INDEX: 'o', // A.k.a.: 'index trace'
   RECORDED_TIME: 'p',
   ROUTE_ID: 'u',
   OCCUPANCY: 'v',
   OCCUPANCY_TITLE: 'x',
   OCCUPANCY_SUB_TITLE: 'z',
   // Old native properties (that were in the old version)
   NEXT_STOP_IS_ACCESSIBLE: 's',
   VEHICLE_IS_ACCESSIBLE: 't',
   // Calculated properties
   ANGLE: '_angle',
   ANIMATORS: '___animator___',
};
