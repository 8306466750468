/**
 * @enum {string}
 */
export default {
   IS_ACCESSIBLE: 'a',
   LINES: 'l',
   NAME: 'currentStreet',
   CURRENT_STREET: 'currentStreet',
   INTERSECTION_STREET: 'nearStreetIntersection',
   ADRESS: 'address',
   TYPE: 'type',
   ETA: 'eta',
   DISTANCE: 'distanceToOriginDestination',
};
