import axios, { CancelTokenSource } from 'axios';

import { Account } from '@/types/authorization';
import { ApiService } from '@/utils/api';

let previousRequest: CancelTokenSource = null as unknown as CancelTokenSource;

export async function getAccount(): Promise<Account> {
   cancelPreviousRequest();

   const response = await ApiService.get('account', { cancelToken: previousRequest.token });
   return response.data as Account;
}

export async function isAuthenticated(): Promise<boolean> {
   const response = await ApiService.get('/account/isauthenticated');
   return response.data as boolean;
}

function cancelPreviousRequest() {
   if (previousRequest) {
      previousRequest.cancel();
   }

   previousRequest = axios.CancelToken.source();
}
