import $ from 'jquery';

import { listen } from 'ol/events';
import olEventType from 'ol/events/EventType';

import Control from './Control';
import { CLASS_UNSELECTABLE } from '../css';
import EventType from '../events/EventType';
import { mapBrowserEventTargetIsOrInElement } from '../util';

/**
 * @classdesc
 * Abtract class for button controls.
 *
 * @extends {Control}
 */
class Button extends Control {
   /**
   * @param {import("ol/control/Control").Options} options Options
   */
   constructor(options) {
      const cls = 'stmap-control-button';

      const $button = $('<a>', {
         'aria-hidden': true,
         class: `${cls} ${CLASS_UNSELECTABLE}`,
      });

      super({
         element: $button[0],
         target: options.target,
      });
   }

   /**
   * @inheritDoc
   */
   setMap(map) {
      super.setMap(map);

      if (map) {
         this.listenerKeys.push(
            listen(this.element, olEventType.CLICK, this.handleButtonClick, this),
         );
         this.listenerKeys.push(
            listen(map, olEventType.DBLCLICK, this.handleMapDoubleClick_, this),
         );
         // Prevent drag events to avoid "shadow" text while dragging
         this.listenerKeys.push(
            listen(
               this.element,
               EventType.DRAGSTART,
               this.handleButtonDragEnter_,
               this,
            ),
         );
      }
   }

   /**
   * @param {Event} evt Event.
   * @protected
   */
   handleButtonClick(evt) {
      evt.preventDefault();
   }

   /**
   * @param {import("ol/MapBrowserEvent").default} evt Event.
   * @return {boolean|undefined} False to prevent event propagation.
   * @private
   */
   handleMapDoubleClick_(evt) {
      var isOrIn = mapBrowserEventTargetIsOrInElement(evt, this.getElement());
      if (isOrIn) {
         return false;
      }
   }

   /**
   * @param {Event} evt Event.
   * @private
   */
   handleButtonDragEnter_(evt) {
      evt.preventDefault();
   }
}

export default Button;
