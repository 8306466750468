import ControlButton from '../control/Button';

/**
 * @classdesc
 * Abtract class for button controls.
 *
 * @extends {ControlButton}
 */
class ProximityControlVehicle extends ControlButton {
   /**
   * @param {import("ol/control/Control").Options} options Options
   */
   constructor(options) {
      super(options);
      const cls = 'stmap-control-proximity-bus';
      const iconCls = options.type === 'Taxi' ? 'icon-taxi-black' : 'icon-ta-black';
      this.$getElement().addClass(`${cls} ${iconCls}`);
      this.btnActive = false;
   }

   /**
   * @inheritDoc
   */
   handleButtonClick(evt) {
      const cls = 'stmap-control-proximity-bus-active';
      if (!this.btnActive) {
         this.$getElement().addClass(`${cls}`);
      } else {
         this.$getElement().removeClass(`${cls}`);
      }
      this.btnActive = !this.btnActive;
      super.handleButtonClick(evt);
      this.registerActivity();
   }

   /**
 * @inheritDoc
 */
   disable(status) {
      const clsActive = 'stmap-control-proximity-bus-active';
      const clsDisable = 'stmap-control-proximity-bus-disable';
      if (status) {
         this.$getElement().removeClass(`${clsActive}`);
         this.$getElement().addClass(`${clsDisable}`);
      } else {
         this.$getElement().removeClass(`${clsActive}`);
         this.$getElement().removeClass(`${clsDisable}`);
      }
   }

   /**
   * @inheritDoc
   */
   setStatus(status) {
      const cls = 'stmap-control-proximity-bus-active';
      this.btnActive = status;
      if (status) {
         this.$getElement().addClass(`${cls}`);
      } else {
         this.$getElement().removeClass(`${cls}`);
      }
   }
}

export default ProximityControlVehicle;
