/**
 * @param {import('ol/color').Color} rgb RGB color
 * @param {number} rgb RGB color
 * @return {import('ol/color').Color} rgba RGBA color
 */
export function rgb2rgba(rgb, opacity = 1) {
   const rgba = rgb.slice(0);
   rgba.push(opacity);
   return rgba;
}
