import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "logo-title" }
const _hoisted_3 = { class: "logo-container" }
const _hoisted_4 = { class: "nav-primary-item-link-icon icon-stm" }
const _hoisted_5 = { class: "nav-primary-item-link-icon-label" }
const _hoisted_6 = { class: "title-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_notification_top = _resolveComponent("notification-top")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          class: "nav-primary-item-link",
          to: { name: 'home' }
        }, {
          default: _withCtx(() => [
            _createElementVNode("i", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("header.home")), 1)
            ])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("h1", {
          ref: "title",
          tabindex: "-1",
          class: "header-title"
        }, _toDisplayString(_ctx.currentPageTitle), 513)
      ])
    ]),
    _createVNode(_component_notification_top)
  ]))
}