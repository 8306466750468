import $ from 'jquery';

import i18n, { toList as i18nToList } from '../../i18n';
import { popupCls } from '../../popup';
import PopupFeature from '../../popup/Feature';
import {
   getLineText as featureGetLineText,
   getLines as featureGetLines,
   getName as featureGetName,
} from './feature';

/**
 * @classdesc
 * MetroStation feature popup (used for proximity)
 *
 * @extends {PopupFeature}
 */
class MetroStationPopup extends PopupFeature {
   /**
   * @param {import("../../popup/Feature").PopupFeatureOptions} options Options
   */
   constructor(options) {
      const feature = options.feature;
      const featureName = featureGetName(feature);
      const featureLines = featureGetLines(feature);

      // === Create content ===
      const cls = `${popupCls}-metrostation`;
      const $content = $('<div>', {
         class: `${cls}-content`,
      });
      const content = $content[0];

      const $top = $('<div>', {
         class: `${cls}-top`,
      }).appendTo($content);

      $('<span>', {
         class: `${popupCls}-icon ${cls}-icon icon-metro`,
      }).appendTo($top);

      const lineTexts = [];
      for (const featureLine of featureLines) {
         $('<span>', {
            class: `${popupCls}-icon ${cls}-line ${cls}-line-${featureLine}`,
         }).appendTo($top);
         lineTexts.push(featureGetLineText(featureLine));
      }

      const $title = $('<div>', {
         class: `${popupCls}-title`,
         text: featureName,
      });

      if (featureLines.length > 1) {
         const lineList = i18nToList(lineTexts);
         $('<div>', {
            class: `${cls}-bottom`,
         })
            .append($title)
            .append(
               $('<div>', {
                  class: `${popupCls}-subtitle`,
                  text: i18n('line') + ' ' + lineList,
               }),
            )
            .appendTo($content);
         $content.addClass(`${cls}-content-multilines`);
      } else {
         $('<div>', {
            class: `${cls}-right`,
         })
            .append($title)
            .append(
               $('<div>', {
                  class: `${popupCls}-subtitle`,
                  text: i18n('line') + ' ' + lineTexts[0],
               }),
            )
            .appendTo($top);
      }

      // === Call parent ===
      super({
      // stmap/popup/Feature
         feature: feature,
         // stmap/popup/Simple
         content: content,
         // stmap/Overlay
         closable: options.closable,
         cls: cls,
         // ol/Overlay
         offset: options.offset,
         position: options.position,
         stopEvent: options.stopEvent,
         insertFirst: options.insertFirst,
         autoPan: options.autoPan,
         autoPanMargin: options.autoPanMargin,
      });
   }
}

export default MetroStationPopup;
