import ScriptInjection from '@/components/scriptInjection/scriptInjection.vue';
import TheHeader from '@/components/header/header.vue';
import TheTabbar from '@/components/tabbar/tabbar.vue';
import { defineComponent } from 'vue';

export default defineComponent({
   name: 'App',
   components: {
      'script-injection': ScriptInjection,
      'the-tabbar': TheTabbar,
      TheHeader,
   },
});
