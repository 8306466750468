let I18N = null;

/**
 * @param {string} key Translation key
 * @return {string} Translated text
 */
export default function i18n(key) {
   return I18N ? I18N.t(key) : '';
}

export function setI18N(i18nExt) {
   if (!I18N && i18nExt) {
      I18N = i18nExt;
   }
}

/**
 * @param {Array.<string>} words List of words to separated by , and
 *     an 'and' at the end.
 * @return {string} list
 */
export function toList(words) {
   let list = '';
   const lastWord = words.pop();
   if (lastWord) {
      const preList = words.join(', ');
      if (preList) {
         const andText = i18n('and');
         list = `${preList} ${andText} ${lastWord}`;
      } else {
         list = lastWord;
      }
   }
   return list;
}
