import { NavigationGuardNext, RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';

import { authorizationService } from '@/services/authorizationService';

export enum Page {
   Home = 'home',
   Information = 'information',
   Menu = 'menu'
}

const routes = [
   {
      path: '/',
      name: Page.Home,
      component: () => import(/* webpackChunkName: "about" */ '@/components/home/home.vue'),
   },
   {
      path: '/information',
      name: Page.Information,
      component: () => import(/* webpackChunkName: "about" */ '@/components/information/information.vue'),
   },
   {
      path: '/menu',
      name: Page.Menu,
      component: () => import(/* webpackChunkName: "about" */ '@/components/menu/menu.vue'),
   },
] as RouteRecordRaw[];

const router = createRouter({
   history: createWebHistory(process.env.BASE_URL),
   routes,
});

const authenticationGuard = async (next: NavigationGuardNext) => {
   if (!await authorizationService.isAuthenticated()) {
      authorizationService.navigateToLogin();
   }

   next();
};

router.beforeEach(async (to, _, next) => {
   await authorizationService.getAccount();
   await authenticationGuard(next);
});

export default router;
