import { includes as arrayIncludes } from 'ol/array';

/**
 * @param {import("ol/source/Vector").default} vectorSource Vector source
 * @param {import("ol/Feature").default} feature Feature
 * @return {boolean} Whether the feature is included in the vector
 *     source or not. If the feature has an id, use the vector source
 *     `getFeatureById` method instead.
 */
export function includes(vectorSource, feature) {
   const features = vectorSource.getFeatures();
   return arrayIncludes(features, feature);
}
