import { includes } from 'ol/array';
import FeatureType from './FeatureType';

/**
 * @enum {string}
 * @private
 */
const Property = {
   /**
   * @type {string}
   */
   HOVERED: '___stmap_hovered___',
   /**
   * @type {string}
   */
   OPACITY: '___stmap_opacity___',
   /**
   * @type {string}
   */
   SELECTED: '___stmap_selected___',
   /**
   * @type {string}
   */
   TYPE: '_t',
   /**
   * @type {string}
   */
   DISPLAY: 'display',
   /**
   * @type {string}
   */
   DISTANCE: 'distanceToOriginDestination',
   /**
   * @type {string}
   */
   TYPE_POINT: 'type',
};

/**
 * Copy the properties from a feature to an other. Does not copy the geometry
 * @param {!Feature} from Feature to copy the properties from
 * @param {!Feature} to Feature to copy the properties to
 */
export function copyProperties(from, to) {
   const fromGeomName = from.getGeometryName();
   const toGeomName = to.getGeometryName();
   const properties = from.getProperties();
   for (const key in properties) {
      if (key !== fromGeomName && key !== toGeomName) {
         to.set(key, properties[key]);
      }
   }
}
/**
 * @param {!Feature} feature Feature
 * @return {string} Whether the feature is hovered or not.
 */
export function getTypePoint(feature) {
   return feature.get(Property.TYPE_POINT);
}
/**
 * @param {!Feature} feature Feature
 * @return {boolean} Whether the feature is hovered or not.
 */
export function getDistance(feature) {
   return feature.get(Property.DISTANCE);
}

/**
 * @param {!Feature} feature Feature
 * @return {boolean} Whether the feature is hovered or not.
 */
export function getDisplay(feature) {
   return !!feature.get(Property.DISPLAY);
}

/**
 * @param {!Feature} feature Feature
 * @return {boolean} Whether the feature is hovered or not.
 */
export function getHovered(feature) {
   return !!feature.get(Property.HOVERED);
}

/**
 * @param {!Feature} feature Feature
 * @param {boolean} hovered Hovered
 */
export function setHovered(feature, hovered) {
   feature.set(Property.HOVERED, hovered);
}

/**
 * @return {number} Feature's opacity
 */
export function getOpacity(feature) {
   let opacity = feature.get(Property.OPACITY);
   if (opacity === undefined) {
      opacity = 1;
   }
   return opacity;
}

/**
 * @param {!Feature} feature Feature
 * @param {?number} opacity Opacity
 */
export function setOpacity(feature, opacity = null) {
   feature.set(Property.OPACITY, opacity);
}

/**
 * @param {!Feature} feature Feature
 * @return {boolean} Whether the feature is selected or not.
 */
export function getSelected(feature) {
   return !!feature.get(Property.SELECTED);
}

/**
 * @param {!Feature} feature Feature
 * @param {boolean} selected Selected
 */
export function setSelected(feature, selected) {
   feature.set(Property.SELECTED, selected);
}

/**
 * @param {!Feature} feature Feature
 * @return {number} Feature type
 */
export function getType(feature) {
   let type = feature.get(Property.TYPE) || 0;
   if (type) {
      type = Number(type);
   } else {
      console.error('Feature error: type is undefined');
   }
   if (!includes(Object.values(FeatureType), type)) {
      console.error(`Feature error: unknown type: ${type}`);
   }
   return /** @type {number} */ (type);
}

/**
 * Find the first feature that has the value of given property among a list.
 * @param {!Array.<Feature>} features List of features
 * @param {string} property Property to compare
 * @param {boolean|number|string} value Value to compare
 * @return {?Feature} Found feature
 */
export function findBy(features, property, value) {
   let found = null;
   const ii = features.length;
   for (let i = 0; i < ii; i++) {
      const feature = features[i];
      if (feature.get(property) === value) {
         found = feature;
         break;
      }
   }
   return found;
}

/**
 * Unset all selected property in all features
 * @param {!Array.<Feature>} features List of features
 */
export function unselectAll(features) {
   const ii = features.length;
   for (let i = 0; i < ii; i++) {
      const feature = features[i];
      setSelected(feature, false);
   }
}
