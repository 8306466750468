import ControlButton from '../control/Button';

/**
 * @classdesc
 * Abtract class for button controls.
 *
 * @extends {ControlButton}
 */
class ProximityControl extends ControlButton {
   /**
   * @param {import("ol/control/Control").Options} options Options
   */
   constructor(options) {
      super(options);

      const cls = 'stmap-control-proximity';
      const iconCls = 'icon-target';

      this.$getElement().addClass(`${cls} ${iconCls}`);
   }

   /**
   * @inheritDoc
   */
   handleButtonClick(evt) {
      super.handleButtonClick(evt);
      this.registerActivity();
   }
}

export default ProximityControl;
