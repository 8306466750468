import {
   ActionTree,
   GetterTree,
   MutationTree,
   createStore,
} from 'vuex';

import { RootState } from '@/store/types';
import { account } from '@/store/modules/account';
import { config } from '@/store/modules/config';
import { metro } from '@/store/modules/metro/metro';
import { nextService } from '@/store/modules/nextService';
import { menu } from '@/store/modules/menu/menu';
import { WarningMessage, WarningMessageId } from '@/types/nextServiceInfo';
import i18n from '@/i18n';

const state: RootState = {
   version: '1.0.0',
   customerIdentifier: null as unknown as number,
   serviceError: null,
};

const getters: GetterTree<RootState, RootState> = {
   locale: () => i18n.global.locale,
   serviceError: state => state.serviceError,
   customerIdentifier: state => state.customerIdentifier,
};

const actions: ActionTree<RootState, RootState> = {
   setServiceError({ commit }, error: string) {
      commit('setServiceError', <WarningMessage>{ id: WarningMessageId.Undefined, message: error });
   },
   setCustomerIdentifier({ commit }, identifier: number) {
      commit('setCustomerIdentifier', identifier);
   },
   clearServiceError({ commit }) {
      commit('setServiceError', null);
   },
   setLocale({ commit }, locale: string) {
      commit('setLocale', locale);
   },
};

const mutations: MutationTree<RootState> = {
   setServiceError(state, error: WarningMessage) {
      state.serviceError = error;
   },
   setCustomerIdentifier(state, identifier: number) {
      state.customerIdentifier = identifier;
   },
   setLocale(state, locale: string) {
      i18n.global.locale = locale;
   },
};

export default createStore({
   state,
   getters,
   actions,
   mutations,
   modules: {
      account,
      nextService,
      metro,
      config,
      menu,
   },
});
