import { mapActions, mapGetters } from 'vuex';

import { Page } from '@/router';
import { WarningMessageId } from '@/types/nextServiceInfo';
import { defineComponent } from 'vue';

export default defineComponent({
   name: 'TheTabbar',
   data() {
      return {
         selectedPage: Page.Home,
         Page,
         sirtaURL: '',
      };
   },
   computed: {
      ...mapGetters('nextService', [
         'trip',
      ]),
      ...mapGetters('config', [
         'config',
      ]),
      ...mapGetters([
         'serviceError',
      ]),
      disableInfoPage(): boolean {
         const error = this.serviceError ?? this.trip?.warningMessage;
         return error ? error.id === WarningMessageId.NoTrip || error.id === WarningMessageId.Undefined : false;
      },
   },
   watch: {
      config(data) {
         if (data) {
            this.sirtaURL = data.sirtaURL;
         }
      },
   },
   async created() {
      if (!this.config) {
         await this.getConfig();
      }
   },
   methods: {
      ...mapActions('config', [
         'getConfig',
      ]),
      selectedMessage(page: Page): string {
         return this.selectedPage === page ? this.$t('tabbar.selected') : '';
      },
      setSelectedPage(page: Page) {
         this.selectedPage = page;
      },
   },
});
