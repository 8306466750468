
import { Stroke, Style } from 'ol/style';

import { getIsPrimary } from './feature';
import BusColor from '../Color';

/**
 * @param {Feature} feature Feature.
 * @param {number} resolution Map view resolution.
 * @return {Style} Style
 */
const styleFunction = function (feature) {
   // The original name was "alternate", i.e. an alternate route is not primary
   const isPrimary = getIsPrimary(feature);
   const opacity = isPrimary ? 1 : 0.3;

   const color = BusColor.LINE.slice(0);
   color.push(opacity);

   return new Style({
      stroke: new Stroke({
         color: color,
         width: 3,
      }),
   });
};

export default styleFunction;
