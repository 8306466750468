import OLInteractionPointer from 'ol/interaction/Pointer';

import { unlistenAllByKey } from '../util';

/**
 * @classdesc
 * Abstract class of pointer interactions. Introduces the concept of
 * listener keys.
 *
 * @extends {OLInteractionPointer}
 */
class Pointer extends OLInteractionPointer {
   /**
   * @param {import("ol/interaction/Pointer").Options=} opt_options Options
   */
   // eslint-disable-next-line
   constructor(opt_options) {
      super(opt_options);

      /**
     * @type {Array.<import("ol/events").EventsKey>}
     * @protected
     */
      this.listenerKeys = [];
   }

   // TODO - this satisfies typecheck, but is pretty ugly...

   /**
   * @inheritDoc
   */
   getMap() {
      return super.getMap();
   }

   /**
   * @inheritDoc
   */
   setMap(map) {
      const currentMap = this.getMap();
      if (currentMap) {
         unlistenAllByKey(this.listenerKeys);
      }

      super.setMap(map);
   }
}

export default Pointer;
