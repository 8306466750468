import Event from 'ol/events/Event';

/**
 * @extends {Event}
 */
class AnimatorEvent extends Event {
   /**
   * @param {import("./AnimatorEventType").default} type Type
   * @param {!import("./Animator").default} animator Animator
   */
   constructor(type, animator) {
      super(type);

      /**
     * @type {!import("./Animator").default}
     */
      this.animator = animator;
   }
}

export default AnimatorEvent;
