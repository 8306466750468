import { Fill, Icon, Stroke, Style, Text } from 'ol/style';

import { ICON_SCALE } from '../../style';
import debug from '../../debug';
import {
   getType as featureType,
} from './feature';

/**
 * @param {Feature} feature Feature.
 * @return {Array.<Style>} Styles (bus and arrow)
 */
const styleFunction = function (feature) {
   const styles = [];

   // Bus position features are not visible above this resolution
   // if (resolution >= 38.21851414258813) {
   //    return styles;
   // }
   // Base icon
   styles.push(getBusPointStyle_(feature));

   if (debug.enabled) {
      styles.push(
         new Style({
            text: new Text({
               font: 'normal 12px arial',
               fill: new Fill({
                  color: [60, 60, 60, 1],
               }),
               offsetY: 20,
               size: 10,
               stroke: new Stroke({
                  color: [255, 255, 255, 1],
                  width: 3,
               }),
            }),
         }),
      );
   }

   return styles;
};

// === BUS ===

/**
 * @param {!Feature} feature Bus point feature.
 * @return {!Style} Style
 * @private
 */
function getBusPointStyle_(feature) {
   const typeIco = featureType(feature);
   return new Style({
      image: new Icon({
         scale: ICON_SCALE,
         src: require('@/assets/images/icon-' + typeIco + '.svg'),
      }),
   });
}

export default styleFunction;
