import Event from 'ol/events/Event';

/**
 * @extends {Event}
 */
class SelectEvent extends Event {
   /**
   * @param {import("./SelectEventType").default} type Type
   * @param {import("ol/Feature").default} feature Feature
   */
   constructor(type, feature) {
      super(type);

      /**
     * @type {import("ol/Feature").default}
     */
      this.feature = feature;
   }
}

export default SelectEvent;
