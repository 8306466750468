import NotificationTop from '@/components/notification/top/notification.vue';
import { defineComponent } from 'vue';

export default defineComponent({
   components: {
      NotificationTop,
   },
   computed: {
      currentPageTitle(): string {
         const currentPage = this.$route.name as string;
         return currentPage ? this.$t(`pageTitle.${currentPage}`) : '';
      },
   },
   watch: {
      currentPageTitle() {
         const title = this.$refs.title as HTMLElement;
         title.focus();
      },
   },
});
