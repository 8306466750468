import resolutions from './resolutions';

/**
 * @type {import("ol/extent").Extent}
 */
const extent = [
   -8252744.6255926,
   5674150.1148498,
   -8153244.2981822,
   5737253.2285429,
];

/**
 * @type {import("ol/coordinate").Coordinate}
 */
const center = [(extent[0] + extent[2]) / 2, (extent[1] + extent[3]) / 2];

/**
 * @type {import("ol/View").ViewOptions}
 */
const viewOptions = {
   center: center,
   zoom: 4,
   resolutions: resolutions,
   extent: extent,
};

export default viewOptions;
