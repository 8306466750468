import OLInteraction from 'ol/interaction/Interaction';

import { unlistenAllByKey } from '../util';

/**
 * @classdesc
 * Abstract class of interactions. Introduces the concept of listener
 * keys.
 *
 * @extends {OLInteraction}
 */
class Interaction extends OLInteraction {
   /**
   * @param {import("ol/interaction/Interaction").Options=} opt_options Options
   */
   // eslint-disable-next-line
   constructor(opt_options) {
      super(opt_options);

      /**
     * @type {Array.<import("ol/events").EventsKey>}
     * @protected
     */
      this.listenerKeys = [];
   }

   // TODO - this satisfies typecheck, but is pretty ugly...

   /**
   * @inheritDoc
   */
   dispatchEvent(evt) {
      super.dispatchEvent(evt);
   }

   /**
   * @inheritDoc
   */
   getMap() {
      return super.getMap();
   }

   /**
   * @inheritDoc
   */
   setMap(map) {
      const currentMap = this.getMap();
      if (currentMap) {
         unlistenAllByKey(this.listenerKeys);
      }

      super.setMap(map);
   }
}

export default Interaction;
