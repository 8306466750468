import $ from 'jquery';

import { includes } from 'ol/array';
import { unlistenByKey } from 'ol/events';

/**
 * @param {number|string} time Time
 * @return {string} Formatted time string
 */
export function formatTime(time) {
   const timeStr = typeof time === 'string' ? time : String(time);
   return timeStr.substr(0, 2) + 'h' + timeStr.substr(2, 4);
}

/**
 * @param {import("ol/MapBrowserEvent").default} evt Map browser event.
 * @return {boolean} Determines whether a given map browser event occured
 *     directly on the <canvas> element (true) of the renderer or on something
 *     else (false).
 */
export function mapBrowserEventIsOnCanvas(evt) {
   const target = evt.originalEvent.target;
   return (
      target instanceof HTMLCanvasElement &&
    $(evt.map.getViewport()).find('> canvas')[0] === target
   );
}

/**
 * @param {import("ol/MapBrowserEvent").default} evt Map browser event.
 * @param {Array.<Element>} elements Elements
 * @return {boolean} Event occured on one of the elements
 */
export function mapBrowserEventIsOnElements(evt, elements) {
   return includes(elements, evt.originalEvent.target);
}

/**
 * @param {import("ol/MapBrowserEvent").default} evt Map browser event.
 * @param {Element} element Element
 * @return {boolean} Event occured on one of the elements
 */
export function mapBrowserEventTargetIsOrInElement(evt, element) {
   const target = evt.originalEvent.target;
   return (
      target === element ||
    (target instanceof Element && $.contains(element, target))
   );
}

/**
 * Pads number to given length
 *
 * "Borrowed" from goog.string
 *
 * @param {number} num The number to pad.
 * @param {number} length The desired length.
 * @return {string} Number as a string with the given options.
 */
export function padNumber(num, length) {
   const s = String(num);
   const index = s.length;
   return new Array(Math.max(0, length - index) + 1).join('0') + s;
}

/**
 * @param {Array.<import("ol/events").EventsKey>} listenerKeys Listener keys
 */
export function unlistenAllByKey(listenerKeys) {
   for (const listenerKey of listenerKeys) {
      unlistenByKey(listenerKey);
   }
   listenerKeys.length = 0;
}

/**
 * @typedef {Object} _DebouncedMixin
 * @property {function(): void} cancel Cancel debounced call
 * @property {function(): boolean} pending Returns whether a call is pending
 */

/**
 * @template {Function} F
 * @typedef {F & _DebouncedMixin} Debounced
 */

/**
 * Debouncing helper shamelessly stolen from lodash master
 *
 * @template {Function} F
 * @param {F} func Function to debounce
 * @param {number} wait Delay
 * @return {Debounced<F>} The debounced function
 */
export function debounce(func, wait) {
   let lastArgs, lastThis, result, timerId, lastCallTime;

   function invokeFunc() {
      const args = lastArgs;
      const thisArg = lastThis;

      lastArgs = lastThis = undefined;
      result = func.apply(thisArg, args);
      return result;
   }

   function startTimer(pendingFunc, wait) {
      return setTimeout(pendingFunc, wait);
   }

   function cancelTimer(id) {
      clearTimeout(id);
   }

   function leadingEdge() {
      // Start the timer for the trailing edge.
      timerId = startTimer(timerExpired, wait);
      // Invoke the leading edge.
      return result;
   }

   function remainingWait(time) {
      const timeSinceLastCall = time - lastCallTime;
      const timeWaiting = wait - timeSinceLastCall;

      return timeWaiting;
   }

   function shouldInvoke(time) {
      const timeSinceLastCall = time - lastCallTime;

      // Either this is the first call, activity has stopped and we're at the
      // trailing edge, the system time has gone backwards and we're treating
      // it as the trailing edge
      return (
         lastCallTime === undefined ||
      timeSinceLastCall >= wait ||
      timeSinceLastCall < 0
      );
   }

   function timerExpired() {
      const time = Date.now();
      if (shouldInvoke(time)) {
         return trailingEdge(time);
      }
      // Restart the timer.
      timerId = startTimer(timerExpired, remainingWait(time));
   }

   function trailingEdge(time) {
      timerId = undefined;

      // Only invoke if we have `lastArgs` which means `func` has been
      // debounced at least once.
      if (lastArgs) {
         return invokeFunc(time);
      }
      lastArgs = lastThis = undefined;
      return result;
   }

   function cancel() {
      if (timerId !== undefined) {
         cancelTimer(timerId);
      }
      lastArgs = lastCallTime = lastThis = timerId = undefined;
   }

   function pending() {
      return timerId !== undefined;
   }

   function debounced(...args) {
      const time = Date.now();
      const isInvoking = shouldInvoke(time);

      lastArgs = args;
      lastThis = this;
      lastCallTime = time;

      if (isInvoking) {
         if (timerId === undefined) {
            return leadingEdge(lastCallTime);
         }
      }
      if (timerId === undefined) {
         timerId = startTimer(timerExpired, wait);
      }
      return result;
   }
   debounced.cancel = cancel;
   debounced.pending = pending;

   // I know what I'm doing, TypeScript...
   // eslint-disable-next-line
  return /** @type {Debounced<F>} */ (/** @type {?} */ (debounced))
}
