import RESOLUTION from './RESOLUTION';

const resolutions = [
   RESOLUTION.R11,
   RESOLUTION.R12,
   RESOLUTION.R13,
   RESOLUTION.R14,
   RESOLUTION.R15,
   RESOLUTION.R16,
   RESOLUTION.R17,
   RESOLUTION.R18,
];

export default resolutions;

/**
 * Returns the closest resolution among the known resolutions less
 * than or equal to a given resolution.
 * @param {number} resolution A given resolution
 * @return {number} Closest resolution
 */
export function closestResolutionFloor(resolution) {
   let foundResolution;

   for (let i = 0, ii = resolutions.length; i < ii; i++) {
      if (resolution >= resolutions[i]) {
         foundResolution = resolutions[i];
         break;
      }
   }

   if (!foundResolution) {
      console.warn('Could not find closest floor resolution. Picking last...');
      foundResolution = resolutions[resolutions.length - 1];
   }

   return foundResolution;
}
