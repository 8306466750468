import {
   getAddress as featureAddress,
   getCurrentStreet as featureCurrentStreet,
   getEta as featureGetEta,
   getIntersectionStreet as featureIntersectionStreet,
} from './feature';

import $ from 'jquery';
import PopupFeature from '../../popup/Feature';
import { popupCls } from '../../popup';
import store from '../../../../store';

/**
 * @classdesc
 * BusStop feature popup (used for proximity)
 *
 * @extends {PopupFeature}
 */
class BusStopPopup extends PopupFeature {
   /**
   * @param {import("../../popup/Feature").PopupFeatureOptions} options Options
   */
   constructor(options) {
      const feature = options.feature;
      const propertyEta = featureGetEta(feature);
      const propertyCurrentStreet = featureCurrentStreet(feature);
      const propertyIntersection = featureIntersectionStreet(feature);
      const propertyAddress = featureAddress(feature);
      const isTrackingActive = store.getters['nextService/isTrackingActive'];

      const address = propertyIntersection === '' ? propertyCurrentStreet : propertyCurrentStreet + ' / ' + propertyIntersection;
      // === Create content ===
      const cls = `${popupCls}-busstop`;
      const $content = $('<div>', {
         class: `${cls}-content`,
      });
      const content = $content[0];

      const $header = $('<div>', {
         class: `${cls}-header`,
      }).appendTo($content);

      $('<div>', {
         class: `${popupCls}-title`,
         text: propertyEta,
      }).appendTo($header);

      $('<div>', {
         class: `${popupCls}-title`,
         text: address,
      }).appendTo($header);

      $('<div>', {
         class: `${popupCls}-title`,
         text: propertyAddress,
      }).appendTo($header);

      // === Call parent ===
      super({
         // stmap/popup/Feature
         feature: feature,
         // stmap/popup/Simple
         content: content,
         // stmap/Overlay
         closable: options.closable,
         cls: cls,
         // ol/Overlay
         offset: options.offset,
         position: options.position,
         stopEvent: options.stopEvent,
         insertFirst: options.insertFirst,
         autoPan: isTrackingActive,
         autoPanMargin: options.autoPanMargin,
         positioning: options.positioning,
      });
   }
}

export default BusStopPopup;
