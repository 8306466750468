import { defineComponent } from 'vue';

export default defineComponent({
   mounted() {
      this.addGoogleTagManagerScript();
      this.addCookieAccessibiltyLink();
   },
   methods: {
      addGoogleTagManagerScript() {
         const content = '!function(e,t,a,r,g){e[r]=e[r]||[],e[r].push({"gtm.start":new Date().getTime(),event:"gtm.js"});var n=t.getElementsByTagName(a)[0],s=t.createElement(a);s.async=!0,s.src="https://www.googletagmanager.com/gtm.js?id="+g+("dataLayer"!=r?"&l="+r:""),n.parentNode.insertBefore(s,n)}(window,document,"script","dataLayer","GTM-57R8PDNM");';
         const sEl = document.createElement('script');
         const tN = document.createTextNode(content);
         sEl.appendChild(tN);
         document.head.insertBefore(sEl, document.head.firstChild);

         const nsEl = document.createElement('noscript');
         const ifEl = document.createElement('iframe');
         ifEl.setAttribute('src', 'https://www.googletagmanager.com/ns.html?id=GTM-57R8PDNM');
         ifEl.setAttribute('height', '0');
         ifEl.setAttribute('width', '0');
         ifEl.setAttribute('style', 'display:none;visibility:hidden');
         nsEl.appendChild(ifEl);
         document.body.insertBefore(nsEl, document.body.firstChild);
      },
      addCookieAccessibiltyLink() {
         const navEl = document.createElement('nav');
         navEl.setAttribute('id', 'skip-links');

         const aEl = document.createElement('a');
         aEl.setAttribute('tabindex', '1');
         aEl.setAttribute('onclick', 'illow.showWidget();document.getElementById("illow-banner-widget").focus()');
         aEl.setAttribute('href', '#');
         aEl.innerHTML = this.$t('cookiesPopup.accessibilityLinkText');

         const liEl = document.createElement('li');
         liEl.append(aEl);

         const ulEl = document.createElement('ul');
         ulEl.append(liEl);

         navEl.append(ulEl);
         document.body.insertBefore(navEl, document.getElementById('app'));
      },
   },
});
