// Todo - move this out of source files

export default {
   bus_position_max_speed: 110,
   bus_position_popup: true,
   bus_position_quick_animation_duration: 1000,
   bus_position_refresh_interval: 1500,
   bus_position_too_old_threshold: 0,
   map_tiles_url: 'http://beta.stm.info',
};
