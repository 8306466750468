import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "message-notification flex-center",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "message-notification-content flex-center" }
const _hoisted_3 = { class: "message-notification-details" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.warningMessage && _ctx.display)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(["message-notification-icon", { 'icon-waiting': _ctx.id === _ctx.WarningMessageId.Waiting,
                        'icon-connection-lost': _ctx.id === _ctx.WarningMessageId.ConnectionLost,
                        'icon-upcomming': _ctx.id === _ctx.WarningMessageId.UpcommingPickup,
                        'icon-warning': _ctx.id === _ctx.WarningMessageId.Undefined,
                        'icon-no-trip': _ctx.id === _ctx.WarningMessageId.NoTrip,
              }])
          }, null, 2),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.message), 1)
        ])
      ]))
    : _createCommentVNode("", true)
}