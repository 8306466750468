/**
 * @enum {string}
 */
export const Type = {
   LINE_1: '1',
   LINE_2: '2',
   LINE_4: '4',
   LINE_5: '5',
   LINE_JUNCTION: 'c',
};

/**
 * @enum {string}
 */
export const TypeText = {
   [`${Type.LINE_1}`]: 'green',
   [`${Type.LINE_2}`]: 'orange',
   [`${Type.LINE_4}`]: 'yellow',
   [`${Type.LINE_5}`]: 'blue',
};
