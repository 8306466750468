
import { listen } from 'ol/events';
import { getChangeEventType } from 'ol/Object';
import LayerProperty from 'ol/layer/Property';

import ControlButton from './Button';

/**
 * @typedef {Object} ToggleLayerOptions
 * @property {import("ol/layer/Vector").default} layer The layer to toggle
 *
 * == ol/control/Control ==
 * @property {HTMLElement} [element] The element is the control's
 * container element. This only needs to be specified if you're developing
 * a custom control.
 * @property {function(import("../MapEvent.js").default)} [render] Function called when
 * the control should be re-rendered. This is called in a `requestAnimationFrame`
 * callback.
 * @property {HTMLElement|string} [target] Specify a target if you want
 * the control to be rendered outside of the map's viewport.
 */

/**
 * @classdesc
 * An abstract button control that toggles a layer visibility
 *
 * @extends {ControlButton}
 */
class ToggleLayer extends ControlButton {
   /**
   * @param {ToggleLayerOptions} options Options
   */
   constructor(options) {
      super(options);

      /**
     * @type {import("ol/layer/Vector").default}
     * @private
     */
      this.layer_ = options.layer;

      /**
     * Must be defined in child class
     * @type {string}
     * @protected
     */
      // eslint-disable-next-line
      this.clsActive;
   }

   /**
   * @inheritDoc
   */
   setMap(map) {
      super.setMap(map);

      if (map) {
         this.listenerKeys.push(
            listen(
               this.layer_,
               getChangeEventType(LayerProperty.VISIBLE),
               this.handleLayerVisibleChange_,
               this,
            ),
         );
         this.handleLayerVisibleChange_();
      }
   }

   /**
   * @inheritDoc
   */
   handleButtonClick(evt) {
      super.handleButtonClick(evt);
      this.layer_.setVisible(!this.layer_.getVisible());
   }

   /**
   * @private
   */
   handleLayerVisibleChange_() {
      const $button = this.$getElement();
      if (this.layer_.getVisible()) {
         $button.addClass(this.clsActive);
         this.registerActivity();
      } else {
         $button.removeClass(this.clsActive);
      }
   }
}

export default ToggleLayer;
