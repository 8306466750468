{
   "and": "and",
   "line": "line",
   "direction": "direction",
   "warning": "Warning",
   "bixi": {
      "available": {
         "bikes": "available bikes",
         "docks": "available docks"
      }
   },
   "map": {
      "attribution": "&copy; OpenStreetMap contributors"
   },
   "metro": {
      "line": {
         "blue": "blue",
         "green": "green",
         "orange": "orange",
         "yellow": "yellow"
      },
      "lines": {
         "blue": "Blue line",
         "green": "Green line",
         "orange": "Orange line",
         "yellow": "Yellow line"
      },
      "station": {
         "passages": "OPERATING HOURS",
         "occupation": "ESTIMATED OCCUPANCY",
         "first": "First",
         "last": "Last",
         "week": "Weekdays",
         "saturday": "Saturday",
         "sunday": "Sunday",
         "frequency": "FREQUENCY",
         "peak": "Peak",
         "offPeak": "Off-peak",
         "min": "min",
         "weekend": "Weekend",
         "connection": "CONNECTIONS",
         "inWeek": "In week",
         "firstPassage": "The first passage is at",
         "lastPassage": "The last passage is at",
         "PeakFrequency": "The peak hour is between",
         "nonPeakFrequency": "The non-peak hour is between",
         "information": "INFORMATION",
         "moreAbout": "Find out more about the station",
         "elevatorDisponible": "Available elevator",
         "occupationStation": {
            "day": "Day:",
            "level1": "Nearly empty.",
            "level2": "Some seats available.",
            "level3": "Standing room only.",
            "distance_possible": "Distancing possible.",
            "distance_impossible": "Distancing not possible.",
            "level4": "Nearly full.",
            "occupationNotAvailable": "Due to the particular configuration of the métro tracks at the {station} station towards {direction}, the estimated metro occupancy level is not available. We will work on this in the later development phases of this functionality."
         }
      }
   },
   "busLines": {
      "stopNotAccessible": "This stop is inaccessible for people with disabilities",
      "addToFavoris": "Add to my favorites",
      "scheduleLine": "Schedule line",
      "readMessage": "Read message"
   },
   "directions": {
      "north": "north",
      "south": "south",
      "west": "west",
      "east": "east"
   },
   "stops": {
      "stopSchedules": "Stop schedule",
      "stopCode": "Stop code",
      "nextDate": "Next date",
      "nextPassages": "Next passages",
      "scheduleWeek": "Weekly schedule",
      "lessThanOneMinute": "less than a minute.",
      "scheduleWeekUrl": "http://www.stm.info/en/info/networks/bus/local/line-",
      "stopNotAccessible": "This stop is inaccessible for people with disabilities",
      "currentOccupancy": "(Current occupancy)"
   },
   "fares": {
      "moreInformation": "More information about our fares",
      "urlInformation": "http://www.stm.info/en/info/fares"
   },
   "messages": {
      "header": {
         "accessibility": "ACCESSIBILITY",
         "avis": "NOTICES",
         "elevator": "ELEVATOR SERVICE STATUS",
         "important": "IMPORTANT",
         "metroState": "MÉTRO SERVICE STATUS"
      },
      "home": {
         "accessibility": "Only passages of buses with a front ramp are displayed."
      },
      "stop": {
         "passages": "There are no other buses for the next twelve hours.",
         "hasNoSchedules": "There are no other buses for"
      },
      "notification": {
         "deniedGeo": "You refused geolocation, some functions may not be supported.",
         "networkSlow": "Slow internet connection: long download time or download impossible.",
         "hasNoBus": "No bus stops within a 1 km radius. Refer to the Schedules section to obtain information about a specific line.",
         "offLine": "No Internet connection: schedules unavailable."
      },
      "welcome": {
         "title": "Hi!",
         "message": "Welcome to our new mobile site. To explore the new features,",
         "linkMessage": "click here.",
         "linkUrl": "http://www.stm.info/en/info/advice/ways-view-bus-schedules/our-new-mobile-site-faq"
      }
   },
   "header": {
      "home": "STM",
      "back": "Back",
      "messages": "Messages",
      "search": "Search",
      "favorites": "Favorites",
      "closeModal": "Close messages modal"
   },
   "calendar": {
      "buttonReset": "Cancel",
      "buttonConfirm": "Ok",
      "calendar": "Calendar",
      "dayMonthYears": "Must contain a date with day month years format"
   },
   "globalSearch": {
      "label": "Search",
      "placeholder": "Search",
      "buttonLabel": "Bus line number, stop code, address or other subject",
      "favoritesLabel": "Search and add to favorites",
      "buttonReset": "Cancel",
      "clearButton": "Clear",
      "gotoItinerary": "Go to itinerary",
      "searchResultHeader": {
         "bus": "BUS",
         "metro": "METRO",
         "stop": "STOP",
         "currentLocation": "MY CURRENT POSITION",
         "place": "PLACES",
         "otherSubject": "OTHER SUBJECT",
         "favorites": "FAVORITES"
      }
   },
   "itinerarySearch": {
      "label": "Search",
      "placeholderarrival": "Arrival",
      "placeholderdeparture": "Departure",
      "min": "Min",
      "today": "Today",
      "suggestedtrips": "SUGGESTED TRIPS",
      "placeholder": "Search",
      "buttonReset": "Cancel",
      "placeholderorigin": "Origin : Address, intersection, postal code, place",
      "buttonLabeldestination": "Destination : Address, intersection, place, your location",
      "noResults": "No results found."
   },
   "itineraryDetails": {
      "takeMetroLine": "Take the ",
      "takeBus": "Take the ",
      "stop": "at the stop",
      "direction": "direction",
      "destination": "Destination",
      "dir": "dir. ",
      "station": "station",
      "bus": " bus ",
      "metro": "Métro",
      "depart": "Departure",
      "minutes": "minutes",
      "walk": "Walk",
      "meter": "m",
      "to": "to",
      "toStop": "to bus stop ",
      "itinerariesCalculated": "Itinerary calculated based on the planned schedule.",
      "itinerariesWheelchair": "Departure times for buses with front ramps are not identified in the itineraries."
   },
   "itineraryOptions": {
      "preference": "PREFERENCES",
      "metro": "Metro",
      "transfers": "Minimize transfers",
      "train": "Train",
      "walk": "Minimize walk",
      "switch": "Switch origin and destination",
      "showOptions": "Show options",
      "addToOptions": "Add to options"
   },
   "menu": {
      "french": "Français",
      "english": "English",
      "logOut": "Log out"
   },
   "favorites": {
      "home": "Home",
      "work": "Work",
      "emptyAddress": "Add the address",
      "info": "Enter your favorite in the field. (Address, intersection, stop code)",
      "gotoItinerary": "Go to itinerary",
      "favorites": "Favorites",
      "delete": "Delete",
      "edit": "Edit",
      "noAddressFound": "Please verify your address and its spelling or try : an intersection (Mont-Royal / St-Denis), a five-digit bus stop code (54321), a place (Cégep Ahuntsic). Only addresses located on the Island of Montreal can be entered."
   },
   "tabbar": {
      "home": "Track vehicle",
      "schedules": "Schedules",
      "information": "Trip info",
      "trip": "Your Trip",
      "sirta": "SIRTA",
      "menu": "Menu",
      "options": "Options",
      "selected": "selected",
      "fares": "Fares"
   },
   "pageTitle": {
      "home": "My paratransit",
      "information": "Trip info",
      "menu": "Menu"
   },
   "information": {
      "informationBanner": "CURRENT TRIP",
      "originAddress": "Pick-up address",
      "destinationAddress": "Drop-off address",
      "boardingRange": "Confirmed pick-up window",
      "boardingRangeTime": "{0} to {1}",
      "vehicleType": "Vehicle type",
      "hiddenOriginAddress": "Pick-up address",
      "hiddenDestinationAddress": "Drop-off address",
      "hiddenBoardingRange": "Confirmed pick-up window {0} to {1}",
      "hiddenVehicleType": "Vehicle type"
   },
   "home": {
      "boarding": "Pick-up",
      "landing": "Drop-off",
      "hiddenETAOrigin": "Your estimated pick-up time is",
      "hiddenETADestination": "Your estimated drop-off time is",
      "hiddenTripMessage": "Trip status",
      "hiddenIntersection": "The vehicle is currently on {0} at the intersection of {1}",
      "hiddenIntersectionShort": "The vehicle is currently on {0}"
   },
   "error": {
      "oups": "Oups!",
      "pageNotAvailable": "The page you're looking for is no longer available, has been moved or the address is misspelled."
   },
   "cookiesPopup": {
      "accessibilityLinkText": "Go to cookies pop-up" 
   }
}