import { Fill, Icon, Stroke, Style, Text } from 'ol/style';
import {
   getAngle as featureGetAngle,
   hasAngle as featureHasAngle,
   getOccupancy as featureOccupancy,
} from './feature';

import { ICON_SCALE } from '../../style';
import debug from '../../debug';

/**
 * @param {Feature} feature Feature.
 * @param {number} resolution Map view resolution.
 * @return {Array.<Style>} Styles (bus and arrow)
 */
const styleFunction = function (feature, resolution) {
   const styles = [];

   // Bus position features are not visible above this resolution
   if (resolution >= 38.21851414258813) {
      return styles;
   }

   // Arrow icon
   const hasAngle = featureHasAngle(feature);
   if (hasAngle) {
      styles.push(getBusArrowStyle_(feature));
   }

   // Base icon
   styles.push(getBusPointStyle_(feature));

   if (debug.enabled) {
      styles.push(
         new Style({
            text: new Text({
               font: 'normal 12px arial',
               fill: new Fill({
                  color: [60, 60, 60, 1],
               }),
               offsetY: 20,
               size: 10,
               stroke: new Stroke({
                  color: [255, 255, 255, 1],
                  width: 3,
               }),
            }),
         }),
      );
   }

   return styles;
};

// === BUS ===

/**
 * @param {!Feature} feature Bus point feature.
 * @param {number} resolution Map view resolution.
 * @return {!Style} Style
 * @private
 */
function getBusPointStyle_(feature) {
   const occupancy = featureOccupancy(feature);
   const radAngle = ((featureGetAngle(feature) - 90) * Math.PI) / 180;
   // console.log(radAngle)
   var directionOccupancy = 'left';
   if (radAngle < -2) {
      directionOccupancy = 'right';
   }
   return new Style({
      image: new Icon({
         scale: ICON_SCALE,
         src: require('@/assets/images/icon-bus-square-cyan-' +
            directionOccupancy +
            '-level-' +
            occupancy +
            '.svg'),
      }),
   });
}

// === BUS DECORATION ===

/**
 * @param {!Feature} feature Bus point feature.
 * @return {!Style} Style
 * @private
 */
function getBusArrowStyle_(feature) {
   const radAngle = ((featureGetAngle(feature) - 90) * Math.PI) / 180;
   return new Style({
      image: new Icon({
         rotation: radAngle,
         scale: ICON_SCALE,
         src: require('@/assets/images/components-bus-arrow-direction.svg'),
      }),
   });
}

export default styleFunction;
