// FIXME - support multiple bus lines

import { TRUE } from 'ol/functions';

import debug from '../../debug';
import { copyProperties as featureCopyProperties } from '../../feature';
import Interaction from '../../interaction/Interaction';
import Animator from './Animator';
import {
   addAnimator as featureAddAnimator,
   getAngleFromLine as featureGetAngleFromLine,
   getBusLineGeometry as featureGetBusLineGeometry,
   getDistanceFromLine as featureGetDistanceFromLine,
   getLogPrefix as featureGetLogPrefix,
   getId as featureGetId,
   getRecordedTime as featureGetRecordedTime,
   isAnimating as featureIsAnimating,
   isCloseToLine as featureIsCloseToLine,
   removeAllAnimators as featureRemoveAllAnimators,
   setAngle as featureSetAngle,
} from './feature';

/**
 * @typedef {Object} ManagerOptions
 * @property {!import("ol/source/Vector").default} busLineSource
 * @property {!import("ol/source/Vector").default} busPositionSource
 */

/**
 * @classdesc
 *
 * Interaction responsible of:
 *
 * - receiving bus position features in order to add them to the source
 * - manage the orientation of the arrow of bus position features
 *
 * Note: this interaction was called `stmap.interaction.busPosition`
 * in the old site. That old version used to be responsible of
 * fetching the records, but that's no longer the case now. They are
 * given from the storage.
 *
 * @extends {Interaction}
 */
class Manager extends Interaction {
   /**
   * @param {ManagerOptions} options Options
   */
   constructor(options) {
      super({
         handleEvent: TRUE,
      });

      // Properties from options

      /**
     * @type {!import("ol/source/Vector").default}
     * @private
     */
      this.busLineSource_ = options.busLineSource;

      /**
     * @type {!import("ol/source/Vector").default}
     * @private
     */
      this.busPositionSource_ = options.busPositionSource;

      // Inner properties

      /**
     * Whether to animate bus position or not.
     * @type {boolean}
     * @private
     */
      this.animate_ = true;

      /**
     * @type {Array.<!number>}
     * @private
     */
      this.busPositionIdsCache_ = [];
   }

   /**
   * @inheritDoc
   */
   setMap(map) {
      const currentMap = this.getMap();
      if (currentMap) {
         this.stop();
      }

      super.setMap(map);
   }

   /**
   * For each bus position feature, if a bus line feature matches,
   * then it is managed to be animated.
   *
   * @param {Array.<import("ol/Feature").default>} features Bus
   *     position features to add
   */
   addFeatures(features) {
      var idsCache = [];
      const ii = features.length;
      const map = this.getMap();

      for (let i = 0; i < ii; i++) {
         const feature = features[i];
         const id = featureGetId(feature);
         const cachePosition = this.busPositionIdsCache_.indexOf(id);

         // Get the bus line geometry for this bus position
         const line = featureGetBusLineGeometry(feature, this.busLineSource_);

         const oldFeature = /** @type {!import("ol/Feature").default} */ (this.busPositionSource_.getFeatureById(
            id,
         ));

         if (cachePosition >= 0) {
            this.busPositionIdsCache_.splice(cachePosition, 1);
         }

         // Modify existing features
         if (cachePosition >= 0 && oldFeature) {
            const newPoint = /** @type import("ol/geom/Point").default */ feature.getGeometry();
            const newCoords = newPoint.getCoordinates();

            const oldRecordedTime = featureGetRecordedTime(oldFeature);
            const newRecordedTime = featureGetRecordedTime(feature);

            if (newRecordedTime > oldRecordedTime) {
               // (1) Set angle
               if (!this.animate_) {
                  if (featureIsCloseToLine(feature, line)) {
                     featureSetAngle(feature, featureGetAngleFromLine(feature, line));
                  } else {
                     featureSetAngle(feature, undefined);
                  }
               }

               // (2) Copy properties from new feature to the old one.
               featureCopyProperties(feature, oldFeature);

               // (3) Set coordinates of old geometry (instead of setting a new geom.
               //     This will trigger the callback method to update the popup...
               if (!this.animate_) {
                  var oldPoint = /** @type import("ol/geom/Point").default */ oldFeature.getGeometry();
                  oldPoint.setCoordinates(newCoords);
               }

               // (4) Set a new animator
               if (this.animate_) {
                  featureAddAnimator(
                     oldFeature,
                     new Animator({
                        end: newCoords,
                        lineString: line,
                        pointFeature: oldFeature,
                        previousRecordedTime: oldRecordedTime,
                     }),
                     map,
                  );
               }
            }
            idsCache.push(oldFeature.getId());

            // Add new features
         } else {
            if (debug.enabled) {
               const recTime = featureGetRecordedTime(feature);
               const logPrefix = featureGetLogPrefix(feature);
               const logTxt =
            logPrefix +
            '\n  recorded time: ' +
            recTime +
            '\n  rec. time str: ' +
            new Date(recTime).toTimeString();
               console.log(logTxt);
            }

            feature.setId(featureGetId(feature));
            if (featureGetDistanceFromLine(feature, line) < 10) {
               featureSetAngle(feature, featureGetAngleFromLine(feature, line));
            }

            this.busPositionSource_.addFeature(feature);
            idsCache.push(feature.getId());
         }
      }

      // Delete features that are not there anymore
      const jj = this.busPositionIdsCache_.length;
      for (let j = 0; j < jj; j++) {
         const cachedId = this.busPositionIdsCache_[j];
         const cachedFeature = this.busPositionSource_.getFeatureById(cachedId);
         if (cachedFeature) {
            // If the feature is not there anymore (in the present) but is still
            // animating (from the past), then keep until the next refresh.
            if (this.animate_ && featureIsAnimating(cachedFeature)) {
               idsCache.push(cachedId);
            } else {
               // Either we are not animating at all, or the feature isn't animating
               // ==> remove the feature.

               // Clearing the geometry makes sure to close any popup that may be
               // already opened with this feature
               cachedFeature.setGeometry(undefined);

               // Remove feature
               this.busPositionSource_.removeFeature(cachedFeature);
            }
         }
      }

      // Update the feature cache
      this.busPositionIdsCache_ = idsCache;
   }

   /**
   * Stop all animations and clear everyting
   */
   stop() {
      // (1) Unset existing features geometry, which will help stopping anything
      //     bound to the geometries.
      const features = this.busPositionSource_.getFeatures();
      const ii = features.length;
      for (let i = 0; i < ii; i++) {
         const feature = features[i];
         feature.setGeometry(undefined);
         featureRemoveAllAnimators(feature);
      }

      // (2) Clear vector features
      this.busPositionSource_.clear();

      // (3) Clear ids cache
      this.busPositionIdsCache_.length = 0;

      // For dev purpose
      // console.log('stoped')
   }
}

export default Manager;
