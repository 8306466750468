import SourceXYZ from 'ol/source/XYZ';
import { hash as tilecoordHash } from 'ol/tilecoord';
import { modulo } from 'ol/math';

/**
 * @classdesc
 * TileCache source.
 */
class TileCache extends SourceXYZ {
   // eslint-disable-next-line
   constructor(opt_options) {
      // eslint-disable-next-line
      const options = opt_options || {};

      super({
         attributions: options.attributions,
         cacheSize: options.cacheSize,
         crossOrigin: options.crossOrigin,
         logo: options.logo,
         opaque: options.opaque,
         projection: options.projection,
         reprojectionErrorThreshold: options.reprojectionErrorThreshold,
         tileGrid: options.tileGrid,
         tileLoadFunction: options.tileLoadFunction,
         tilePixelRatio: options.tilePixelRatio,
         tileUrlFunction: tileUrlFunction,
         url: options.url,
         urls: options.urls,
         wrapX: options.wrapX !== undefined ? options.wrapX : true,
         transition: options.transition,
      });

      this.layer_ = options.layer;
      this.extension_ = options.extension || '.png';
   }
}

/**
 * @param {import("ol/tilecoord").TileCoord} tileCoord Tile Coordinate
 * @param {number} pixelRatio Pixel ratio
 * @param {import("ol/proj").ProjectionLike} projection Projection
 * @return {string} Tile url
 */
function tileUrlFunction(tileCoord) {
   const urls = this.urls;
   if (!urls) {
      return undefined;
   }

   let baseUrl;
   if (urls.length === 1) {
      baseUrl = urls[0];
   } else {
      const index = modulo(tilecoordHash(tileCoord), urls.length);
      baseUrl = urls[index];
   }
   const z = padNumber(tileCoord[0], 2);
   let x = padNumber(tileCoord[1], 9);

   const maxY = Math.floor(
      (20037508.342789248 + 20037508.342789248) /
      (256 * this.getTileGrid().getResolutions()[tileCoord[0]]),
   );
   const tileY = maxY + tileCoord[2];
   let y = padNumber(tileY, 9);

   x = x.match(/.{3}/g).join('/');
   y = y.match(/.{3}/g).join('/');

   return [baseUrl, this.layer_, z, x, y].join('/') + this.extension_;
}

/**
 * @param {number} num Num
 * @param {number} length Length
 * @return {string} Pad number
 */
function padNumber(num, length) {
   const s = String(num);
   const index = s.length;
   return new Array(Math.max(0, length - index) + 1).join('0') + s;
}

export default TileCache;
