import $ from 'jquery';

import { listen } from 'ol/events';
import EventType from 'ol/events/EventType';
import OLOverlay from 'ol/Overlay';

import { unlistenAllByKey } from './util';

/**
 * @typedef {Object} OverlayOptions
 * @property {string|undefined} [cls] Additionnal cls class.
 * @property {boolean|undefined} [closable] Whether the overlay can be
 *     closed or not. Defaults to `false`.
 *
 * == ol/Overlay ==
 * @property {number|string} [id] Set the overlay id. The overlay id can be used
 * with the {@link module:ol/Map~Map#getOverlayById} method.
 * @property {HTMLElement} [element] The overlay element.
 * @property {Array<number>} [offset=[0, 0]] Offsets in pixels used when positioning
 * the overlay. The first element in the
 * array is the horizontal offset. A positive value shifts the overlay right.
 * The second element in the array is the vertical offset. A positive value
 * shifts the overlay down.
 * @property {import("ol/coordinate").Coordinate} [position] The overlay position
 * in map projection.
 * @property {OverlayPositioning} [positioning='top-left'] Defines how
 * the overlay is actually positioned with respect to its `position` property.
 * Possible values are `'bottom-left'`, `'bottom-center'`, `'bottom-right'`,
 * `'center-left'`, `'center-center'`, `'center-right'`, `'top-left'`,
 * `'top-center'`, and `'top-right'`.
 * @property {boolean} [stopEvent=true] Whether event propagation to the map
 * viewport should be stopped. If `true` the overlay is placed in the same
 * container as that of the controls (CSS class name
 * `ol-overlaycontainer-stopevent`); if `false` it is placed in the container
 * with CSS class name specified by the `className` property.
 * @property {boolean} [insertFirst=true] Whether the overlay is inserted first
 * in the overlay container, or appended. If the overlay is placed in the same
 * container as that of the controls (see the `stopEvent` option) you will
 * probably set `insertFirst` to `true` so the overlay is displayed below the
 * controls.
 * @property {boolean} [autoPan=false] If set to `true` the map is panned when
 * calling `setPosition`, so that the overlay is entirely visible in the current
 * viewport.
 * @property {import("ol/Overlay").PanOptions} [autoPanAnimation] The
 * animation options used to pan the overlay into view. This animation is only
 * used when `autoPan` is enabled. A `duration` and `easing` may be provided to
 * customize the animation.
 * @property {number} [autoPanMargin=20] The margin (in pixels) between the
 * overlay and the borders of the map when autopanning.
 * @property {string} [className='ol-overlay-container ol-selectable'] CSS class
 * name.
 */

/**
 * @classdesc
 * Abstract class for overlays. Introduces:
 *
 * - the concept of listener keys
 * - close methods
 * - a element that can be created in the child class that, if defined and when
 *   clicked, closes this overlay.
 *
 * @extends {OLOverlay}
 */
class Overlay extends OLOverlay {
   /**
   * @param {OverlayOptions} options Options for the overlay
   */
   constructor(options) {
      super(/** @type {import("ol/Overlay").Options} */ (options));

      // Properties from ol.Overlay (to satisfy typecheck)

      /**
     * @type {boolean}
     * @protected
     */
      // eslint-disable-next-line
      this.autoPan;

      // Native properties

      /**
     * @type {?Element}
     * @protected
     */
      this.closeEl = null;

      /**
     * @type {Array.<import("ol/events").EventsKey>}
     * @protected
     */
      this.listenerKeys = [];

      if (options.cls) {
         $(this.getElement()).addClass(options.cls);
      }
   }

   // TODO - this satisfies typecheck, but is pretty ugly...
   /**
   * @inheritDoc
   */
   getElement() {
      return super.getElement();
   }

   // TODO - this satisfies typecheck, but is pretty ugly...
   /**
   * @inheritDoc
   */
   getMap() {
      return super.getMap();
   }

   // TODO - this satisfies typecheck, but is pretty ugly...
   /**
   * @inheritDoc
   */
   handlePositionChanged() {
      super.handlePositionChanged();
   }

   /**
   * @inheritDoc
   */
   setMap(map) {
      const currentMap = this.getMap();
      if (currentMap) {
         unlistenAllByKey(this.listenerKeys);
      }

      super.setMap(map);

      if (map) {
         if (this.closeEl) {
            this.listenerKeys.push(
               listen(this.closeEl, EventType.CLICK, this.handleCloseElClick_, this),
            );
         }
      }

      if (this.autoPan) {
         this.handlePositionChanged();
      }
   }

   /**
   * @param {!Event} evt Event.
   * @private
   */
   handleCloseElClick_(evt) {
      evt.preventDefault();
      this.close();
   }

   /**
   * Close this overlay by removing it from the map.
   */
   close() {
      if (!this.isClosed()) {
         const map = this.getMap();
         map.removeOverlay(this);
      }
   }

   /**
   * @return {boolean} Whether the overlay is closed or not, i.e. it's
   *     considered closed if not in a map.
   */
   isClosed() {
      return !this.getMap();
   }
}

export default Overlay;
