import {
   PopupBusPosition,
   PopupBusStop,
   PopupMetroStation,
} from '../popup';
import {
   getSelected as featureGetSelected,
   setSelected as featureSetSelected,
} from '../feature';

import Interaction from '../interaction/Interaction';
import { LineString } from 'ol/geom';
import OverlayPositioning from 'ol/OverlayPositioning';
import { TRUE } from 'ol/functions';
import context from '../context';
import { getCenter as extentGetCenter } from 'ol/extent';
import { includes as sourceVectorIncludes } from '../source/vector';

/**
 * @typedef {Object} PopupManagerOptions
 * @property {SourceVector|undefined} [bixiSource] Source containing
 *     bixi features
 * @property {SourceVector|undefined} [busPositionSource] Source
 *     containing bus position features
 * @property {SourceVector|undefined} [busStopProximitySource] Source
 *     containing bus position features
 * @property {SourceVector|undefined} [originProximitySource] Source
 *  *     containing bus position features
 * @property {SourceVector|undefined} [destinationProximitySource] Source
 *     containing bus stop proximity features
 * @property {SourceVector|undefined} [metroStationProximitySource]
 *     Source containing metro station proximity features
 */

/**
 * @classdesc
 * @extends {Interaction}
 */
class PopupManager extends Interaction {
   /**
   * @param {PopupManagerOptions} options Options
   */
   constructor(options) {
      super({
         handleEvent: TRUE,
      });

      /**
     * @type {?SourceVector}
     * @private
     */
      this.bixiSource_ = options.bixiSource || null;

      /**
     * @type {?SourceVector}
     * @private
     */
      this.busPositionSource_ = options.busPositionSource || null;

      /**
     * @type {?SourceVector}
     * @private
     */
      this.busStopProximitySource_ = options.busStopProximitySource || null;

      /**
     * @type {?SourceVector}
     * @private
     */
      this.originProximitySource_ = options.originProximitySource || null;

      /**
     * @type {?SourceVector}
     * @private
     */
      this.destinationProximitySource_ =
         options.destinationProximitySource || null;

      /**
     * @type {?SourceVector}
     * @private
     */
      this.metroStationProximitySource_ =
         options.metroStationProximitySource || null;

      /**
     * @type {?PopupFeature}
     * @private
     */
      this.popup_ = null;
      /**
   * @type {?PopupFeature}
   * @private
   */
      this.center_ = null;
   }

   degreeAngle(point1, point2) {
      var dy = point2[1] - point1[1];
      var dx = point2[0] - point1[0];
      var theta = Math.atan2(dx, dy); // range (-PI, PI]
      theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
      return Math.round(theta * 10000) / 10000;
   }

   distanceBetweenTwoPoints(point1, point2) {
      var line = new LineString([point1, point2]);
      return line.getLength();
   }

   getOverlayPositioning(segmentAngle, distance) {
      var positioning = OverlayPositioning.BOTTOM_CENTER;
      if (distance < 200) {
         return OverlayPositioning.BOTTOM_CENTER;
      }
      if (segmentAngle > 0 && segmentAngle < 90) {
         positioning = OverlayPositioning.BOTTOM_LEFT;
      } else if (segmentAngle >= 90 && segmentAngle <= 180) {
         positioning = OverlayPositioning.TOP_LEFT;
      } else if (segmentAngle <= -1 && segmentAngle > -90) {
         positioning = OverlayPositioning.BOTTOM_RIGHT;
      } else if (segmentAngle <= -90) {
         positioning = OverlayPositioning.TOP_RIGHT;
      } else {
         positioning = OverlayPositioning.BOTTOM_CENTER;
      }
      return positioning;
   }

   /**
   * Create then show a popup. Hide all popups first, if any.
   * @param {!Feature} feature Feature
   * @return {boolean} A popup was created and shown
   */
   createAndShowPopup(feature) {
      this.closePopup();

      var popup = this.createPopup_(feature);
      if (popup) {
         var map = this.getMap();
         map.addOverlay(popup);
         this.popup_ = popup;

         // If feature is not selected, select it.
         if (!featureGetSelected(feature)) {
            featureSetSelected(feature, true);
         }
      }

      return !!popup;
   }

   /**
   * Close popup, if any.
   */
   closePopup() {
      if (this.popup_) {
         this.popup_.close();
         this.popup_ = null;
      }
   }

   /**
   * @param {!Feature} feature Feature
   * @return {?PopupFeature} A popup.
   * @private
   */
   createPopup_ = function (feature) {
      var map = this.getMap();
      var center = map.getView().getCenter();
      let popup = null;
      const geom = feature.getGeometry();
      var segmentAngle = this.degreeAngle(geom.getExtent(), center);
      var distance = this.distanceBetweenTwoPoints(geom.getExtent(), center);
      var positioning = this.getOverlayPositioning(segmentAngle, distance);

      if (geom) {
         const popupOptions = {
            closable: true,
            autoPan: false,
            feature: feature,
            position: extentGetCenter(geom.getExtent()),
            positioning: positioning,
         };

         // BUS POSITION

         // context.bus_position_quick_animation_duration

         // if (
         //   context.bus_position_popup !== false &&
         //   !popup &&
         //   this.busPositionSource_
         // ) {
         //   sourceFeature = this.busPositionSource_.getFeatureById(id)
         //   if (sourceFeature && sourceFeature == feature) {
         //     ol.obj.assign(popupOptions, {
         //       follow: false
         //     })
         //     popup = new PopupBusPosition(popupOptions)
         //   }
         // }

         if (
            context.bus_position_popup !== false &&
            !popup &&
            sourceVectorIncludes(this.busPositionSource_, feature)
         ) {
            popup = new PopupBusPosition(popupOptions);
         }

         // ORIGIN PROXIMITY
         if (
            !popup &&
            this.originProximitySource_ &&
            sourceVectorIncludes(this.originProximitySource_, feature)
         ) {
            popup = new PopupBusStop(popupOptions);
         }

         // DESTINATION PROXIMITY
         if (
            !popup &&
            this.destinationProximitySource_ &&
            sourceVectorIncludes(this.destinationProximitySource_, feature)
         ) {
            popup = new PopupBusStop(popupOptions);
         }
         // BUS STOP PROXIMITY
         if (
            !popup &&
            this.busStopProximitySource_ &&
            sourceVectorIncludes(this.busStopProximitySource_, feature)
         ) {
            popup = new PopupBusStop(popupOptions);
         }

         // METRO STATION PROXIMITY
         if (
            !popup &&
            this.metroStationProximitySource_ &&
            sourceVectorIncludes(this.metroStationProximitySource_, feature)
         ) {
            popup = new PopupMetroStation(popupOptions);
         }
      }

      return popup;
   }

   /**
   * Get feature in the popup, if any.
   * @return {?Feature} Feature
   */
   getPopupFeature() {
      let feature = null;
      if (this.popup_) {
         if (this.popup_.isClosed()) {
            this.popup_ = null;
         } else {
            feature = this.popup_.getFeature();
         }
      }
      return feature;
   }
}

export default PopupManager;
