import Feature from 'ol/Feature';
import { remove as arrayRemove } from 'ol/array';
import { listenOnce } from 'ol/events';
import {
   boundingExtent as extentBoundingExtent,
   containsCoordinate as extentContainsCoordinate,
} from 'ol/extent';
import LineString from 'ol/geom/LineString';

import debug from '../../debug';
import { findBy as featureFindBy } from '../../feature';
import LineProperty from '../line/Property';
import { formatTime } from '../../util';
import AnimatorEventType from './AnimatorEventType';
import Property from './Property';

// ===========
//  Constants
// ===========

/**
 * Distance in meters below which a point or coordinate (bus position) has to
 * be located to be considered close to a line (bus line), i.e the distance
 * has to be lesser than this value.
 * @type {number}
 * @private
 */
const CloseToLineDistance_ = 10;

/**
 * For dev purpose
 * @type {boolean}
 * @private
 */
const LogOnAddAnimator_ = true;

/**
 * For dev purpose
 * @type {boolean}
 * @private
 */
const LogOnGetBusLineGeometry_ = false;

// ==========================
//  Property utility methods
// ==========================

/**
 * @param {!Feature} feature Feature.
 * @return {number} Id of the feature
 */
export function getId(feature) {
   return Number(feature.getId());
}

/**
 * @param {!Feature} feature Feature.
 * @return {boolean} Whether the feature has angle or not
 */
export function hasAngle(feature) {
   return feature.get(Property.ANGLE) !== undefined;
}

/**
 * @param {!Feature} feature Feature.
 * @return {!number} Angle of the feature
 */
export function getAngle(feature) {
   var angle = feature.get(Property.ANGLE);
   if (angle !== undefined) {
      return /** @type {!number} */ (angle);
   }
   return 0;
}

/**
 * DEPRECATED
 * @param {!Feature} feature Feature.
 * @return {!number} Bus line index of the feature
 */
export function getBusLineIndex(feature) {
   var index = /** @type {number|null|undefined} */ (feature.get(
      Property.BUS_LINE_INDEX,
   ));
   if (index === undefined || index === null) {
      index = 0;
   }
   return index;
}

/**
 * @param {!Feature} busPositionFeature Feature.
 * @param {!import("ol/source/Vector").default} busLineSource Source
 * @return {!LineString} Bus line geometry.
 */
export function getBusLineGeometry(busPositionFeature, busLineSource) {
   let lineGeom;

   const busLineFeatures = busLineSource.getFeatures();

   const routeId = getRouteId(busPositionFeature);

   if (debug.enabled && LogOnGetBusLineGeometry_) {
      var logPrefix = getLogPrefix(busPositionFeature);
      console.log(logPrefix + 'route id: ' + routeId);
   }

   const busLineFeature = featureFindBy(
      busLineFeatures,
      LineProperty.ROUTE_ID,
      routeId,
   );

   if (busLineFeature) {
      const geom = busLineFeature.getGeometry();
      if (geom instanceof LineString) {
         lineGeom = geom;
      }
   }

   if (!lineGeom) {
      console.error('Bus line geometry should be LineString');
   }

   return /** @type {!LineString} */ (lineGeom);
}

/**
 * @param {!Feature} feature Feature.
 * @return {boolean} Is at stop
 */
export function getIsAtStop(feature) {
   return feature.get(Property.IS_AT_STOP) === 1;
}

/**
 * @param {!Feature} feature Feature.
 * @return {boolean} Is congestion
 */
export function getIsCongestion(feature) {
   return feature.get(Property.IS_CONGESTION) === 1;
}

/**
 * @param {!Feature} feature Feature.
 * @return {number} Occupancy.
 */
export function getOccupancyTitle(feature) {
   return /** @type {number} */ (feature.get(Property.OCCUPANCY_TITLE));
}

/**
 * @param {!Feature} feature Feature.
 * @return {number} Occupancy.
 */
export function getOccupancySubTitle(feature) {
   return /** @type {number} */ (feature.get(Property.OCCUPANCY_SUB_TITLE));
}

/**
 * @param {!Feature} feature Feature.
 * @return {number} Occupancy.
 */
export function getOccupancy(feature) {
   return /** @type {number} */ (feature.get(Property.OCCUPANCY));
}

/**
 * @param {!Feature} feature Feature.
 * @return {number} Next stop identifier.
 */
export function getNextStopIdentifier(feature) {
   var nextStop =
   /** @type {?number|undefined} */ (feature.get(
         Property.NEXT_STOP_IDENTIFIER,
      )) || 0;
   return nextStop;
}

/**
 * @param {!Feature} feature Feature.
 * @return {boolean} Next stop is accessible. Defaults to `true`, i.e. if the
 *     property is not set, then the next stop is considered accessible.
 */
export function getNextStopIsAccessible(feature) {
   return feature.get(Property.NEXT_STOP_IS_ACCESSIBLE) !== 0;
}

/**
 * @param {!Feature} feature Feature.
 * @return {string} Next stop name.
 */
export function getNextStopName(feature) {
   var nextStop =
   /** @type {?string|undefined} */ (feature.get(Property.NEXT_STOP_NAME)) ||
    '';
   return nextStop;
}

/**
 * @param {!Feature} feature Feature.
 * @return {string} Formatted time until next stop.
 */
export function getNextStopTimeFormatted(feature) {
   var formatted = '';
   var isReal =
   /** @type {number|undefined} */ (feature.get(Property.IS_REAL)) === 1;
   var time =
   /** @type {string|number|undefined} */ (feature.get(
         Property.NEXT_STOP_TIME,
      )) || 0;
   if (isReal) {
      if (time === 0 || time === '0') {
         time = '< 1';
      }
      formatted = time + ' minute';
      if (time > 1) {
         formatted += 's';
      }
   } else {
      formatted = formatTime(time);
   }
   return formatted;
}

/**
 * @param {!Feature} feature Feature.
 * @return {number} Recorded time in milliseconds since 1970.
 */
export function getRecordedTime(feature) {
   return /** @type {number} */ (feature.get(Property.RECORDED_TIME));
}

/**
 * @param {!Feature} feature Feature.
 * @param {number|undefined} angle Angle.
 */
export function setAngle(feature, angle) {
   feature.set(Property.ANGLE, angle);
}

/**
 * @param {!import("ol/coordinate").Coordinate} point1 Coordinates
 * @param {!import("ol/coordinate").Coordinate} point2 Coordinates
 * @return {!number} Angle to the Y axis
 */
export function degreeAngle(point1, point2) {
   var dy = point2[1] - point1[1];
   var dx = point2[0] - point1[0];
   var theta = Math.atan2(dx, dy); // range (-PI, PI]
   theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
   return Math.round(theta * 10000) / 10000;
}

/**
 * @param {!import("ol/coordinate").Coordinate} closestPoint Coordinates
 * @param {!import("ol/coordinate").Coordinate} point1 Coordinates
 * @param {!import("ol/coordinate").Coordinate} point2 Coordinates
 * @return {number?} Angle to the Y axis
 */
export function angleBetweenPoints(closestPoint, point1, point2) {
   // Build segment extent
   var extent = extentBoundingExtent([point1, point2]);

   if (extentContainsCoordinate(extent, closestPoint)) {
      var segmentAngle = degreeAngle(point1, point2);
      var pointAngle = degreeAngle(point1, closestPoint);
      if (segmentAngle === pointAngle) {
         return segmentAngle;
      }
   }

   return null;
}

/**
 * @param {!Feature|!import("ol/coordinate").Coordinate} object Either
 *     a feature or coordinate.
 * @param {!LineString} line Line geometry.
 * @return {number} Distance in geometry unit between point and line
 */
export function getDistanceFromLine(object, line) {
   var point;
   if (object instanceof Feature) {
      var geom = /** @type Point */ object.getGeometry();
      point = geom.getCoordinates();
   } else {
      point = object;
   }

   var closestPoint = line.getClosestPoint(point);
   var distance = new LineString([point, closestPoint]);

   return distance.getLength();
}

/**
 * @param {!Feature} feature Feature.
 * @param {!LineString} line Line geometry.
 * @return {number|undefined} Angle of the feature
 */
export function getAngleFromLine(feature, line) {
   var geom = /** @type Point */ feature.getGeometry();
   var point = geom.getCoordinates();
   var closestPoint = line.getClosestPoint(point);

   var angle = line.forEachSegment(angleBetweenPoints.bind(null, closestPoint));

   if (typeof angle !== 'number') {
      angle = undefined;
   }

   return angle;
}

/**
 * @param {!Feature} feature Feature.
 * @return {boolean} Whether the feature has network or not
 */
export function hasNetwork() {
   return false;
}

/**
 * @param {!Feature|!import("ol/coordinate").Coordinate} object Either
 *     a feature or coordinate.
 * @param {!LineString} line Line geometry.
 * @return {boolean} Whether the object is close to the line or not.
 */
export function isCloseToLine(object, line) {
   var distance = CloseToLineDistance_;
   return getDistanceFromLine(object, line) < distance;
}

/**
 * @param {!Feature} feature Feature.
 * @return {boolean} Whether the feature is on planned route or not
 */
export function isOnRoute() {
   return false;
}

/**
 * @param {!Feature} feature Feature.
 * @return {string} Log prefix.
 */
export function getLogPrefix(feature) {
   var id = getId(feature);
   return 'Bus Position feature (' + id + ') - ';
}

/**
 * @param {import("ol/Feature").default} feature Feature
 * @return {string} Line + direction + index of route
 */
export function getRouteId(feature) {
   return /** @type {string} */ (feature.get(Property.ROUTE_ID));
}

// ============================ //
//  Animators - Public methods  //
// ============================ //

/**
 * @param {!Feature} feature Feature.
 * @param {!import("./Animator").default} animator Animator.
 * @param {!import("ol/PluggableMap").default} map Map.
 */
export function addAnimator(feature, animator, map) {
   var animators = getAnimators_(feature);
   animators.push(animator);

   if (debug.enabled && LogOnAddAnimator_) {
      var logPrefix = getLogPrefix(feature);
      console.log(logPrefix + 'has {' + animators.length + '} animator(s).');
   }

   enableFirstAnimator_(feature, map);
}

/**
 * @param {!Feature} feature Feature.
 */
export function removeAllAnimators(feature) {
   var animators = getAnimators_(feature);
   var nAnimators = animators.length;

   for (var i = nAnimators - 1, ii = 1; i > ii; i--) {
      arrayRemove(animators, animators[i]);
   }

   var firstAnimator = animators[0];
   if (firstAnimator) {
      if (firstAnimator.getMap()) {
         firstAnimator.stop();
      } else {
         arrayRemove(animators, firstAnimator);
      }
   }

   // For dev purpose
   // console.log(animators)
}

/**
 * @param {!Feature} feature Feature.
 * @return {boolean} Whether the feature has an active animator or not. Only
 *     the first one needs to be checked.
 */
export function isAnimating(feature) {
   var animators = getAnimators_(feature);
   var firstAnimator = animators[0];
   return !!firstAnimator && !!firstAnimator.getMap();
}

// ============================= //
//  Animators - Private methods  //
// ============================= //

/**
 * @param {!Feature} feature Feature.
 * @param {!import("ol/PluggableMap").default} map Map.
 * @private
 */
function enableFirstAnimator_(feature, map) {
   var animators = getAnimators_(feature);

   // If there's a first animator
   var firstAnimator = animators[0];
   if (firstAnimator && !firstAnimator.getMap()) {
      // (1) Listen once when it stops
      listenOnce(
         firstAnimator,
         AnimatorEventType.STOP,
         handleOnceAnimatorStop_.bind(undefined, feature, map),
         undefined,
      );
      // (2) Enable it, i.e. add it to the map
      map.addInteraction(firstAnimator);
   }
}

/**
 * @param {!Feature} feature Feature.
 * @return {!Array.<!import("./Animator").default>} List of animators
 *     bound to this bus position feature. Gets created if was undefined.
 * @private
 */
function getAnimators_(feature) {
   var animators = /** @type {!Array.<!import("./Animator").default>|undefined} */ (feature.get(
      Property.ANIMATORS,
   ));
   if (animators === undefined) {
      animators = [];
      feature.set(Property.ANIMATORS, animators);
   }
   return animators;
}

/**
 * @param {!Feature} feature Feature.
 * @param {!import("ol/PluggableMap").default} map Map.
 * @param {!import("./AnimatorEvent").default} evt Event
 * @private
 */
function handleOnceAnimatorStop_(feature, map, evt) {
   var stoppedAnimator = evt.animator;

   // (1) Remove stopped animator from the list
   var animators = getAnimators_(feature);
   arrayRemove(animators, stoppedAnimator);

   // (2) Enable next one
   enableFirstAnimator_(feature, map);
}
