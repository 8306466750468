import Feature from 'ol/Feature';
import { listen } from 'ol/events';
import { TRUE } from 'ol/functions';
import { Point } from 'ol/geom';
import LayerVector from 'ol/layer/Vector';
import RenderEventType from 'ol/render/EventType';
import SourceVector from 'ol/source/Vector';
import { Stroke, Style } from 'ol/style';

import { equal as coordinateEqual } from '../../coordinate';
import context from '../../context';
import debug from '../../debug';
import { getLineStringSegment as geomGetLineStringSegment } from '../../geom';
import Interaction from '../../interaction/Interaction';
import AnimatorEvent from './AnimatorEvent';
import AnimatorEventType from './AnimatorEventType';
import {
   getAngleFromLine as featureGetAngleFromLine,
   isCloseToLine as featureIsCloseToLine,
   getLogPrefix as featureGetLogPrefix,
   getRecordedTime as featureGetRecordedTime,
   setAngle as featureSetAngle,
} from './feature';

/**
 * @typedef {Object} AnimatorOptions
 * @property {import("ol/coordinate").Coordinate} end The location
 *     where the animation ends.
 * @property {!import("ol/geom/LineString").default} lineString The
 *     line string geometry to animate the point feature on.
 * @property {!import("ol/Feature").default} pointFeature The feature
 *     to animate alongside the line. Its geometry must be:
 *     `Point`.
 * @property {number} previousRecordedTime Previous recorded time in
 *     milliseconds since 1970. Used to calculate the duration of the
 *     animation.
 */

/**
 * Delay, in milliseconds. At any time (before animating or while animating),
 * if the creation time of an animator goes past this longevity, it is stopped.
 * @type {number}
 * @private
 */
const longevity_ = 1 * 60 * 1000; // m s ms

/**
 * @classdesc
 * Abstract class of interactions. Introduces the concept of listener
 * keys.
 *
 * @extends {Interaction}
 */
class Animator extends Interaction {
   /**
   * @param {AnimatorOptions} options Options
   */
   constructor(options) {
      super({
         handleEvent: TRUE,
      });

      const feature = options.pointFeature;
      const endCoordinate = options.end;
      const lineString = options.lineString;

      // Calculate duration of the animation (in milliseconds).
      const recordedTime = featureGetRecordedTime(feature);
      const previousRecordedTime = options.previousRecordedTime;
      const animationDuration = recordedTime - previousRecordedTime;

      if (debug.enabled) {
         var logPrefix = featureGetLogPrefix(feature);
         var logTxt =
        logPrefix +
        '\n  Previous recorded time: ' +
        previousRecordedTime +
        '\n                          ' +
        new Date(previousRecordedTime).toTimeString() +
        '\n  Current recorded time:  ' +
        recordedTime +
        '\n                          ' +
        new Date(recordedTime).toTimeString() +
        '\n  Animation duration:     ' +
        animationDuration;
         console.log(logTxt);
      }

      /**
     * The feature to animate alongside the line. Its geometry must be:
     * `Point`.
     * @type {!Feature}
     * @private
     */
      this.pointFeature_ = feature;

      var point = this.pointFeature_.getGeometry();
      if (!(point instanceof Point)) {
         throw Error('BusPositionAnimator - geometry must be a point');
      }

      /**
     * How many milliseconds the animation should last
     * @type {number}
     * @private
     */
      this.animationDuration_ = animationDuration;

      /**
     * The time when this animator was created, which is different than the
     * "actualTimeStart" that is the time when this is activated. Animators
     * are stacked in a list and only the older one is active.
     * @type {number}
     * @private
     */
      this.creationTime_ = new Date().getTime();

      /**
     * The location where the animation ends.
     * @type {import("ol/coordinate").Coordinate}
     * @private
     */
      this.endCoordinate_ = endCoordinate;

      /**
     * The line string geometry to animate the point feature on.
     * @type {!LineString}
     * @private
     */
      this.lineString_ = lineString;

      /**
     * @type {number}
     * @private
     */
      this.previousRecordedTime_ = previousRecordedTime;

      /**
     * @type {number}
     * @private
     */
      this.recordedTime_ = recordedTime;

      /**
     * The actual time when the animation begun, i.e. obtained using
     * `new Date().getTime()`. The value returned is in milliseconds since 1970.
     * @type {number}
     * @private
     */
      // eslint-disable-next-line
      this.actualTimeStart_;

      /**
     * The end coordinate "snapped" onto the line string.  NOTE: gets
     * automatically computed upon activation, when determining if we
     * can animate or not. The end coordinate and closest end
     * coordinate must be very close to one an other for the animation
     * to be considered valid.
     *
     * @type {import("ol/coordinate").Coordinate}
     * @private
     */
      // eslint-disable-next-line
      this.closestEndCoordinate_;

      /**
     * The start coordinate "snapped" onto the line string.  NOTE:
     * gets automatically computed upon activation, when determining
     * if we can animate or not. The start coordinate and closest
     * start coordinate must be very close to one an other for the
     * animation to be considered valid.
     *
     * @type {import("ol/coordinate").Coordinate}
     * @private
     */
      // eslint-disable-next-line
      this.closestStartCoordinate_;

      /**
     * @type {?LayerVector}
     * @private
     */
      this.tmpLayer_ = null;

      /**
     * Segment to follow by the animation.
     * @type {!LineString}
     * @private
     */
      // eslint-disable-next-line
      this.segment_;

      /**
     * Obtained from the point feature upon activation. Why wait,
     * i.e. why not compute this right away? Animators are in
     * stack. The point geometry changes a lot. Upon activation, this
     * animator becomes responsible of manipulating the geometry of
     * the point, therefore it's the perfect time to compute this.
     * @type {import("ol/coordinate").Coordinate}
     * @private
     */
      // eslint-disable-next-line
      this.startCoordinate_;
   }

   /**
   * @inheritDoc
   */
   setMap(map) {
      const currentMap = this.getMap();
      if (currentMap) {
         if (this.tmpLayer_) {
            currentMap.removeLayer(this.tmpLayer_);
            this.tmpLayer_.getSource().clear();
            this.tmpLayer_ = null;
         }
      }

      super.setMap(map);

      if (map) {
         this.actualTimeStart_ = new Date().getTime();

         if (this.canAnimate_()) {
            // Start

            this.listenerKeys.push(
               listen(
                  map,
                  RenderEventType.POSTCOMPOSE,
                  this.handleMapPostCompose_,
                  this,
               ),
            );

            if (debug.enabled) {
               var tmpSource = new SourceVector();
               tmpSource.addFeature(new Feature(this.segment_));
               var tmpLayer = new LayerVector({
                  source: tmpSource,
                  style: new Style({
                     stroke: new Stroke({
                        color: '#ff0000',
                        width: 2,
                     }),
                  }),
               });
               map.addLayer(tmpLayer);
               this.tmpLayer_ = tmpLayer;
            }
         } else {
            this.stop(true);
         }
      }
   }

   /**
   * @param {boolean=} jump Whether to set the geometry of the feature to the
   *     end coordinate. This will be done without any animation.
   *     Defaults to `false`.
   */
   stop(jump = false) {
      var feature = this.pointFeature_;
      var map = this.getMap();
      if (map) {
         if (debug.enabled) {
            var logPrefix = featureGetLogPrefix(feature);
            var logTxt = logPrefix + 'Animation stop';
            console.log(logTxt);
         }

         map.removeInteraction(this);

         if (jump) {
            var point = /** @type {Point|undefined} */ (feature.getGeometry());
            if (point) {
               point.setCoordinates(this.endCoordinate_);
               this.setAngle_();
            }
         }

         this.dispatchEvent(new AnimatorEvent(AnimatorEventType.STOP, this));
      }
   }

   /**
   * The are several conditions that require to be met in order for the bus
   * position feature to support being animated.
   *
   * - the animator must not be too old
   * - the point feature has geometry
   * - the animation duration must be greater than 0. If that's not the case,
   *   then something might be wrong with the data received.
   * - the start coordinate must be close to the line
   * - the end coordinate must be close to the line as well
   * - the start and end coordinates must be different
   * - there must be a segment (line string) found between the closest start and
   *   and coordinates to make the animation follow the line.
   *
   * @return {boolean} Whether the bus position can animate or not.
   * @private
   */
   canAnimate_() {
      var canAnimate = true;
      var cantMsg = '';
      var feature = this.pointFeature_;
      var quick = false;
      var startCoordinate;
      var segment;

      if (this.isTooOld_()) {
         canAnimate = false;
         cantMsg = 'Animator is too old.';
      }

      var point = feature.getGeometry();
      if (canAnimate && !point) {
         canAnimate = false;
         cantMsg = 'Feature has no geometry';
      }

      if (canAnimate && !(this.animationDuration_ > 0)) {
         canAnimate = false;
         cantMsg = `Invalid animation duration: ${this.animationDuration_}`;
      }

      if (canAnimate) {
         startCoordinate = /** @type {Point} */ (point).getCoordinates();
         if (coordinateEqual(startCoordinate, this.endCoordinate_)) {
            canAnimate = false;
            cantMsg = 'Start and end coordinates are the same';
         }
      }

      if (canAnimate && !this.isCloseToLine_(feature)) {
         canAnimate = false;
         cantMsg = 'Start point is too far from line';
      }

      if (canAnimate && !this.isCloseToLine_(this.endCoordinate_)) {
         canAnimate = false;
         cantMsg = 'End point is too far from line';
      }

      if (canAnimate) {
         var timeDiff = this.actualTimeStart_ - this.previousRecordedTime_;
         if (timeDiff > context.bus_position_too_old_threshold) {
            // We are past the threshold.  We can still animate, but we need to set
            // the animation duration to the value of "quick animation duration",
            // which should be quick.
            quick = true;
         }
      }

      if (canAnimate && startCoordinate) {
      // Set start coordinates
         this.startCoordinate_ = startCoordinate.slice(0);

         // Get "closest" start and end coordinates for segment computing
         var closestStartCoordinate = this.lineString_.getClosestPoint(
            this.startCoordinate_,
         );
         var closestEndCoordinate = this.lineString_.getClosestPoint(
            this.endCoordinate_,
         );

         // Get segment
         segment = geomGetLineStringSegment(
            this.lineString_,
            closestStartCoordinate,
            closestEndCoordinate,
         );

         if (segment) {
            this.segment_ = segment;
         } else if (canAnimate) {
            canAnimate = false;
            cantMsg =
          'Could not get line between closest start and and coordinates.';
         }
      }

      if (canAnimate && segment) {
      // Validate that the bus is not going to fast.

         // Convert max speed from KM/h to meters per second
         var busMaxSpeed = (context.bus_position_max_speed * 1000) / 60 / 60;
         var segmentLength = segment.getLength();
         // segment length is in meters, animation duration in milliseconds. Get
         // meters per seconds speed
         var busSpeed = (segmentLength / this.animationDuration_) * 1000;
         if (busSpeed > busMaxSpeed) {
            canAnimate = false;
            cantMsg = 'Bus is going too fast.';
         }
      }

      if (canAnimate && quick) {
         this.animationDuration_ = context.bus_position_quick_animation_duration;
      }

      if (cantMsg && debug.enabled) {
         var logPrefix = featureGetLogPrefix(feature);
         var logTxt = logPrefix + 'Cannot animate because: ' + cantMsg;
         console.log(logTxt);
      }

      return canAnimate;
   }

   /**
   * @param {number=} opt_now Now time.
   * @return {boolean} Whether this animator is too old or not.
   * @private
   */
   isTooOld_(optNow) {
      var now = optNow || new Date().getTime();
      return now - this.creationTime_ > longevity_;
   }

   /**
   * Set the angle of the point feature relative to its position to the line.
   * @private
   */
   setAngle_() {
      var feature = this.pointFeature_;
      var line = this.lineString_;

      var angle = this.isCloseToLine_(feature)
         ? featureGetAngleFromLine(feature, line)
         : undefined;

      featureSetAngle(feature, angle);
   }

   /**
   * @param {!Feature|!import("ol/coordinate").Coordinate} object
   *     Either a feature or coordinate.
   * @return {boolean} Whether the object is close to the line or not.
   */
   isCloseToLine_(object) {
      return featureIsCloseToLine(object, this.lineString_);
   }

   /**
   * Called on map 'postcompose' event fired.  Adjust the position of the point
   * (bus) feature depending on the time elapsed since the animation started.
   * @param {!import("ol/render/Event").default} evt Event
   * @private
   */
   handleMapPostCompose_() {
      var map = this.getMap();
      var feature = this.pointFeature_;
      var point = feature.getGeometry();

      // If, at any moment, the geometry of the feature stops to be a point (in
      // other words, if the geometry is unset), stop!
      if (!point || !(point instanceof Point)) {
         this.stop();
         return;
      }

      var nowMilliseconds = new Date().getTime();

      // If the animator is too old, stop but also jump to the last known location
      if (this.isTooOld_(nowMilliseconds)) {
         this.stop(true);
         return;
      }

      var elapsedMilliseconds = nowMilliseconds - this.actualTimeStart_;
      var fraction = elapsedMilliseconds / this.animationDuration_;
      if (fraction > 1) {
         fraction = 1;
      }

      var newCoordinate = this.segment_.getCoordinateAt(fraction);

      // Set new coordinate
      /** @type {!Point} */
      point.setCoordinates(newCoordinate);

      // Set new angle
      this.setAngle_();

      map.render();

      // If we're past end of the animation, stop!
      if (fraction === 1) {
         this.stop();
      }
   }
}

export default Animator;
