/**
 * @module stmap/events/EventType
 */

/**
 * @enum {string}
 * @const
 */
export default {
   DRAGSTART: 'dragstart',
};
