import $ from 'jquery';

import { popupCls } from '../../popup';
import PopupFeature from '../../popup/Feature';
import {
   getOccupancyTitle as featureOccupancyTitle,
   getOccupancySubTitle as featureOccupancySubTitle,
   getOccupancy as featureOccupancy,
} from './feature';

/**
 * @classdesc
 * BusStop feature popup (used for proximity)
 *
 * @extends {PopupFeature}
 */
class PopupBusPosition extends PopupFeature {
   /**
   * @param {import("../../popup/Feature").PopupFeatureOptions} options Options
   */
   constructor(options) {
      const feature = options.feature;
      const occupancyTitle = featureOccupancyTitle(feature);
      const occupancySubTitle = featureOccupancySubTitle(feature);
      const occupancy = featureOccupancy(feature);

      // const featureId = featureGetId(feature)
      // const featureName = featureGetName(feature)
      // const featureLines = featureGetLines(feature)
      // console.log(feature)
      const cls = `${popupCls}-position`;
      const iconCls =
      occupancy === 0
         ? `${cls}-line-busicon-${occupancy} icon-info1`
         : `${popupCls}-icon ${cls}-line-busicon-${occupancy} icon-level-${occupancy}`;
      // === Create content ===
      const $content = $('<div>', {
         class: `${cls}-content`,
      });
      const content = $content[0];

      // eslint-disable-next-line
      const $header = $('<div>', {
         class: `${cls}-header`,
      }).appendTo($content);

      const $ico = $('<span>', {
         class: `${iconCls}`,
      }).appendTo($content);

      $('<span>', {
         class: 'path1',
      }).appendTo($ico);

      $('<span>', {
         class: 'path2',
      }).appendTo($ico);

      $('<span>', {
         class: 'path3',
      }).appendTo($ico);

      $('<span>', {
         class: 'path4',
      }).appendTo($ico);

      $('<div>', {
         class: `${popupCls}-title`,
         text: occupancyTitle,
      }).appendTo($content);

      $('<div>', {
         class: `${popupCls}-subtitle`,
         text: occupancySubTitle,
      }).appendTo($content);

      // === Call parent ===
      super({
      // stmap/popup/Feature
         feature: feature,
         // stmap/popup/Simple
         content: content,
         // stmap/Overlay
         closable: options.closable,
         cls: cls,
         // ol/Overlay
         offset: options.offset,
         position: options.position,
         stopEvent: options.stopEvent,
         insertFirst: options.insertFirst,
         autoPan: options.autoPan,
         autoPanMargin: options.autoPanMargin,
      });
   }
}

export default PopupBusPosition;
