// TODO is_terminus -> validate

/**
 * @enum {string}
 */
export default {
   IS_TERMINUS: 'o',
   LINES: 'l',
   NAME: 'n',
   TYPE: 't',
};
