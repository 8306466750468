import {
   GetterTree,
   Module,
} from 'vuex';
import { GreenLine, MetroState, OrangeLine, YellowLine, BlueLine } from '@/types/metro';
import { RootState } from '@/store/types';
import geojson from './metro.json';

const state: MetroState = {
   lines: [
      new GreenLine(),
      new OrangeLine(),
      new YellowLine(),
      new BlueLine(),
   ],
   metroStationGeoJson: geojson,
};

const getters: GetterTree<MetroState, RootState> = {
   metroLines: state => state.lines,
   metroStationGeoJson: state => state.metroStationGeoJson,
};

export const metro: Module<MetroState, RootState> = {
   namespaced: true,
   state,
   getters,
};
