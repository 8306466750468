// TODO - define allowed elements
// TODO - include popup manager

import MapBrowserEventType from 'ol/MapBrowserEventType';
import { includes } from 'ol/array';
import { listen } from 'ol/events';
import { TRUE } from 'ol/functions';
import LayerVector from 'ol/layer/Vector';

import Pointer from './Pointer';
import SelectEvent from './SelectEvent';
import SelectEventType from './SelectEventType';
import { mapBrowserEventIsOnCanvas, mapBrowserEventIsOnElements } from '../util';

/**
 * @typedef {Object} SelectOptions
 * @property {!Array.<Element>} [allowedElements] Elements on which to
 *     allow 'click' events to fall through in the map.
 * @property {!Array.<LayerVector>} [layers] Only the layers defined
 *     in here can be interacted with.
 * @property {!import("../popup/Manager").default} [popupManager] A
 *     reference to the popup manager to use to create popups.
 */

/**
 * @classdesc
 * @extends {Pointer}
 */
class Select extends Pointer {
   /**
   * @param {SelectOptions} options Options
   */
   constructor(options) {
      super({
         handleEvent: TRUE,
      });

      /**
     * @type {!Array.<LayerVector>}
     * @private
     */
      this.layers_ = options.layers.filter(layer => {
         return layer instanceof LayerVector;
      });

      /**
     * @type {!Array.<Element>}
     * @private
     */
      this.allowedElements_ = options.allowedElements;

      /**
     * @type {!import("../popup/Manager").default}
     * @private
     */
      this.popupManager_ = options.popupManager;
   }

   /**
   * @inheritDoc
   */
   setMap(map) {
      super.setMap(map);

      if (map) {
         this.listenerKeys.push(
            listen(map, MapBrowserEventType.CLICK, this.handleMapClick_, this),
         );
      }
   }

   /**
   * @param {import("ol/layer/Layer").default} layer Layer.
   * @return {boolean} Whether the layer is included among the layers.
   */
   layerFilter_(layer) {
      return includes(this.layers_, layer);
   }

   /**
   * @param {import("ol/MapBrowserEvent").default} evt Event
   * @private
   */
   handleMapClick_ = function (evt) {
      // No need to do anything if the event did not occur on the <canvas> of
      // the map renderer.
      if (
         !mapBrowserEventIsOnCanvas(evt) &&
      !mapBrowserEventIsOnElements(evt, this.allowedElements_)
      ) {
         return;
      }

      const features = evt.map.getFeaturesAtPixel(evt.pixel, {
         hitTolerance: 5,
         layerFilter: this.layerFilter_.bind(this),
      });
      let feature;

      if (features && features.length > 0) {
         feature = features[0];
      }

      if (feature) {
         const popupCreated = this.popupManager_.createAndShowPopup(feature);
         if (!popupCreated) {
            this.dispatchEvent(new SelectEvent(SelectEventType.CLICK, feature));
         }
      }
   }
}

export default Select;
