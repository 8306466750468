import i18n from '@/i18n';
export abstract class MetroLine {
   abstract publicIdentifier: string;
   abstract center:number[];
   abstract description: string;

   get category(): string {
      return 'metro';
   }
}

export class GreenLine extends MetroLine {
   publicIdentifier: string = '1';
   center: number[] = [-73.5714, 45.504]; // Station McGill
   get description(): string {
      return i18n.global.t('metro.lines.green');
   }
}
export class OrangeLine extends MetroLine {
   publicIdentifier: string = '2';
   center: number[] = [-73.5672, 45.4981]; // Station Bonaventure
   get description(): string {
      return i18n.global.t('metro.lines.orange');
   }
}

export class YellowLine extends MetroLine {
   publicIdentifier: string = '4';
   center: number[] = [-73.5327, 45.5124]; // Station Jean-Drapeau
   get description(): string {
      return i18n.global.t('metro.lines.yellow');
   }
}

export class BlueLine extends MetroLine {
   publicIdentifier: string = '5';
   center: number[] = [-73.615, 45.5204]; // Station Outremont
   get description(): string {
      return i18n.global.t('metro.lines.blue');
   }
}

export interface MetroState {
   lines:MetroLine[];
   metroStationGeoJson: unknown;
}
