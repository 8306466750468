import {
   ActionTree,
   GetterTree,
   Module,
   MutationTree,
} from 'vuex';
import { ConfigDto, ConfigState } from '@/types/config';

import { RootState } from '../types';
import { getConfig } from '@/api/config';

export const state: ConfigState = {
   config: undefined as unknown as ConfigDto,
};

export const getters: GetterTree<ConfigState, RootState> = {
   config: state => state.config,
};

export const actions: ActionTree<ConfigState, RootState> = {
   async getConfig({ commit }) {
      getConfig().then((data: ConfigDto) => {
         commit('setConfig', data);
      });
   },
};

export const mutations: MutationTree<ConfigState> = {
   setConfig(state, result: ConfigDto) {
      state.config = result;
   },
};

export const config: Module<ConfigState, RootState> = {
   namespaced: true,
   state,
   getters,
   actions,
   mutations,
};
