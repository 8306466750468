{
   "and": "et",
   "line": "ligne",
   "direction": "direction",
   "warning": "Avertissement",
   "bixi": {
      "available": {
         "bikes": "vélos disponibles",
         "docks": "points d'ancrage disponibles"
      }
   },
   "map": {
      "attribution": "&copy; les contributeurs d’OpenStreetMap"
   },
   "metro": {
      "line": {
         "blue": "bleue",
         "green": "verte",
         "orange": "orange",
         "yellow": "jaune"
      },
      "lines": {
         "blue": "Ligne bleue",
         "green": "Ligne verte",
         "orange": "Ligne orange",
         "yellow": "Ligne jaune"
      },
      "station": {
         "passages": "PASSAGES",
         "occupation": "TAUX D’OCCUPATION ESTIMÉ",
         "first": "Premier",
         "last": "Dernier",
         "week": "Semaine",
         "saturday": "Samedi",
         "sunday": "Dimanche",
         "frequency": "FRÉQUENCE",
         "peak": "Pointe",
         "offPeak": "Hors Pointe",
         "min": "min",
         "weekend": "Fin de semaine",
         "connection": "CORRESPONDANCES",
         "inWeek": "En semaine",
         "firstPassage": "Le premier passage est à",
         "lastPassage": "le dernier passage est à",
         "PeakFrequency": "l'heure de pointe est entre",
         "nonPeakFrequency": "hors pointe est entre",
         "information": "INFORMATION",
         "moreAbout": "En savoir plus sur cette station",
         "elevatorDisponible": "Ascenseurs disponible",
         "occupationStation": {
            "day": "Journée:",
            "level1": "Train presque vide.",
            "level2": "Quelques places assises.",
            "level3": "Places debout seulement.",
            "level4": "Train presque plein.",
            "distance_possible": "Distanciation possible.",
            "distance_impossible": "Distanciation impossible.",
            "occupationNotAvailable": "En raison de la configuration particulière des voies de métro à la station {station} en direction {direction}, l’estimation du taux d’occupation n’est pas disponible."
         }
      }
   },
   "busLines": {
      "stopNotAccessible": "Cet arrêt est inaccessible pour les personnes handicapées",
      "addToFavoris": "Ajouter aux favoris",
      "scheduleLine": "Horaire de la ligne",
      "readMessage": "Lire le message"
   },
   "stops": {
      "stopSchedules": "Horaire de l'arrêt",
      "stopCode": "Code d'arrêt",
      "nextDate": "Prochaine date",
      "nextPassages": "Prochains passages",
      "scheduleWeek": "Horaire de la semaine",
      "lessThanOneMinute": "moins d'une minute.",
      "scheduleWeekUrl": "http://www.stm.info/fr/infos/reseaux/bus/reseau-local/ligne-",
      "stopNotAccessible": "Cet arrêt est inaccessible pour les personnes handicapées",
      "currentOccupancy": "(Occupation actuelle)"
   },
   "fares": {
      "moreInformation": "Plus d'informations sur nos titres et tarifs",
      "urlInformation": "https://www.stm.info/fr/infos/titres-et-tarifs"
   },
   "messages": {
      "header": {
         "accessibility": "ACCESSIBILITÉ",
         "avis": "AVIS",
         "elevator": "ÉTAT DES ASCENSEURS",
         "important": "IMPORTANT",
         "metroState": "ÉTAT DU SERVICE MÉTRO"
      },
      "home": {
         "accessibility": "Seuls les bus avec rampe avant sont affichés."
      },
      "stop": {
         "passages": "Il n'y a pas de passage de bus pour les douze prochaines heures.",
         "hasNoSchedules": "Il n'y a pas de passage à venir pour le"
      },
      "notification": {
         "deniedGeo": "Vous avez refusé la géolocalisation, certaines fonctions pourraient être désactivées.",
         "networkSlow": "Connexion Internet faible : téléchargement long ou impossible.",
         "hasNoBus": "Pas d’arrêts de bus dans un rayon de 1 km. Consultez la section horaire pour obtenir les données d’une ligne spécifique.",
         "offLine": "Pas de connexion Internet : horaires indisponibles."
      },
      "welcome": {
         "title": "Bonjour !",
         "message": "Bienvenue sur notre nouveau site mobile. Pour en savoir plus sur ses nouvelles fonctionnalités,",
         "linkMessage": "cliquez ici.",
         "linkUrl": "http://www.stm.info/fr/tempsreel/faq-nouveau-site-mobile"
      }
   },
   "directions": {
      "north": "nord",
      "south": "sud",
      "west": "ouest",
      "east": "est"
   },
   "header": {
      "home": "STM",
      "back": "Retour",
      "messages": "Messages",
      "search": "Recherche",
      "favorites": "Favoris",
      "closeModal": "fermer la boite de dialogue messages"
   },
   "calendar": {
      "buttonReset": "Annuler",
      "buttonConfirm": "Ok",
      "calendar": "Calendrier",
      "dayMonthYears": "Doit contenir une date avec le format jours mois année"
   },
   "globalSearch": {
      "label": "Rechercher",
      "placeholder": "Recherche",
      "buttonLabel": "No de ligne, code d'arrêt, adresse ou autre sujet",
      "favoritesLabel": "Rechercher et ajouter un favori",
      "buttonReset": "Cancel",
      "clearButton": "Supprimé la recherche",
      "gotoItinerary": "Allez à l'itinéraire",
      "searchResultHeader": {
         "bus": "BUS",
         "metro": "MÉTRO",
         "stop": "ARRÊTS",
         "currentLocation": "MA POSITION ACTUELLE",
         "place": "LIEUX",
         "otherSubject": "AUTRE SUJET",
         "favorites": "FAVORIS"
      }
   },
   "itinerarySearch": {
      "label": "Rechercher",
      "placeholderarrival": "Arrivée",
      "placeholderdeparture": "Départ",
      "min": "Min",
      "today": "Aujourd'hui",
      "suggestedtrips": "TRAJETS SUGGÉRÉS",
      "placeholder": "Recherche",
      "placeholderorigin": "Départ : adresse, intersection, code postal, lieu",
      "buttonLabeldestination": "Arrivée :  adresse, intersection, lieu, votre position",
      "buttonReset": "Cancel",
      "noResults": "Aucun résultat trouvé."
   },
   "itineraryDetails": {
      "takeMetroLine": "Prenez le ",
      "takeBus": "Prenez le ",
      "stop": "à l'arrêt ",
      "station": "station",
      "bus": " ",
      "metro": "",
      "direction": "en direction ",
      "destination": "Destination",
      "dir": "dir.",
      "depart": "Départ",
      "minutes": "minutes",
      "walk": "Marcher",
      "meter": "m",
      "to": "jusqu'à",
      "toStop": "jusqu'à l'arrêt ",
      "itinerariesCalculated": "Trajet calculé selon l'horaire planifié",
      "itinerariesWheelchair": "Les passages avec rampe avant ne sont pas identifiés dans les trajets."
   },
   "itineraryOptions": {
      "preference": "PRÉFÉRENCES",
      "metro": "Inclure le métro",
      "transfers": "Minimiser les correspondances",
      "train": "Inclure le train",
      "walk": "Minimiser la marche à pied",
      "switch": "Inverser le départ et l'arrivée",
      "showOptions": "Afficher les options",
      "addToOptions": "Ajouter aux options"
   },
   "menu": {
      "french": "Français",
      "english": "English",
      "logOut": "Déconnexion"
   },
   "favorites": {
      "home": "Domicile",
      "work": "Travail",
      "emptyAddress": "Ajoutez l'adresse",
      "info": "Saisissez votre favori dans le champ. (Adresse, intersection, code d’arrêt)",
      "gotoItinerary": "Allez à l'itinéraire",
      "favorites": "Favoris",
      "delete": "Supprimer",
      "edit": "Modifier",
      "noAddressFound": "Vérifiez votre adresse et l’orthographe de celle-ci ou essayez d’inscrire: une intersection (Mont-Royal / St-Denis), un code d'arrêt à cinq chiffres (54321), un lieu (Cégep Ahuntsic). Seules les adresses situées sur l’île de Montréal peuvent être saisies."
   },
   "tabbar": {
      "home": "Suivre véhicule",
      "schedules": "Transport adapté",
      "information": "Info déplacement",
      "trip": "Itinéraires",
      "sirta": "SIRTA",
      "menu": "Menu",
      "options": "Options",
      "selected": "sélectionné",
      "fares": "Tarifs"
   },
   "pageTitle": {
      "home": "Mon transport adapté",
      "information": "Info déplacement",
      "menu": "Menu"
   },
   "information": {
      "informationBanner": "DÉPLACEMENT EN COURS",
      "originAddress": "Adresse d'origine",
      "destinationAddress": "Adresse de destination",
      "boardingRange": "Plage confirmée d’embarquement",
      "boardingRangeTime": "Entre {0} et {1}",
      "vehicleType": "Type de véhicule",
      "hiddenOriginAddress": "Adresse d'origine",
      "hiddenDestinationAddress": "Adresse de destination",
      "hiddenBoardingRange": "Plage confirmée d’embarquement entre {0} et {1}",
      "hiddenVehicleType": "Type de véhicule"
   },
   "home": {
      "boarding": "Embarquement",
      "landing": "Débarquement",
      "hiddenETAOrigin": "L’heure de votre embarquement est estimée à",
      "hiddenETADestination": "L’heure de votre débarquement est estimée à",
      "hiddenTripMessage": "Statut du déplacement",
      "hiddenIntersection": "Le véhicule est présentement sur {0} à l’intersection de {1}",
      "hiddenIntersectionShort": "Le véhicule est présentement sur {0}"
   },
   "error": {
      "oups": "Oups!",
      "pageNotAvailable": "Désolé, la page que vous cherchez a bougé, ou son adresse est incorrecte."
   },
   "cookiesPopup": {
      "accessibilityLinkText": "Aller au pop-up des témoins de navigation" 
   }
}