
import { Circle, Fill, Style, Stroke } from 'ol/style';

import { isTerminus as featureIsTerminus } from './feature';
import MetroColor from '../Color';
import { rgb2rgba } from '../../color';
import {
   getOpacity as featureGetOpacity,
   getSelected as featureGetSelected,
   getType as featureGetType,
} from '../../feature';
import FeatureType from '../../FeatureType';
import RESOLUTION from '../../RESOLUTION';
import { closestResolutionFloor } from '../../resolutions';

/**
 * @param {!Feature} feature Feature.
 * @param {number} resolution Map view resolution.
 * @return {!Style} Style
 */
export default function (feature, resolution) {
   const selected = featureGetSelected(feature);
   // eslint-disable-next-line
   const isTerminus = featureIsTerminus(feature);
   const opacity = featureGetOpacity(feature);
   let fillColor = [255, 255, 255, opacity];
   let radius = 1;
   let strokeColor;
   const strokeWidth = 2;

   const type = featureGetType(feature);

   switch (type) {
      case FeatureType.METRO_STATION_JUNCTION:
         strokeColor = rgb2rgba(MetroColor.LINE_JUNCTION, opacity);
         break;
      case FeatureType.METRO_STATION_1:
         strokeColor = rgb2rgba(MetroColor.LINE_1, opacity);
         break;
      case FeatureType.METRO_STATION_2:
         strokeColor = rgb2rgba(MetroColor.LINE_2, opacity);
         break;
      case FeatureType.METRO_STATION_4:
         strokeColor = rgb2rgba(MetroColor.LINE_4, opacity);
         break;
      case FeatureType.METRO_STATION_5:
         strokeColor = rgb2rgba(MetroColor.LINE_5, opacity);
         break;
      default:
         console.error('Metro station type is not defined in feature.');
   }

   const closestResolution = closestResolutionFloor(resolution);
   switch (closestResolution) {
      case RESOLUTION.R11:
      case RESOLUTION.R12:
         radius = 0;
         break;
      case RESOLUTION.R13:
         radius = 4;
         break;
      case RESOLUTION.R14:
         radius = 5;
         break;
      case RESOLUTION.R15:
      case RESOLUTION.R16:
         radius = 6;
         break;
      case RESOLUTION.R17:
      case RESOLUTION.R18:
         radius = 7;
         break;
      default:
         radius = 0;
         break;
   }

   if (radius !== 0 && selected) {
      radius += 1;
      strokeColor = [255, 255, 255, opacity];
      fillColor = [0, 0, 0, opacity];
   }

   let styleOptions;
   if (radius !== 0) {
      styleOptions = {
         image: new Circle({
            fill: new Fill({
               color: fillColor,
            }),
            radius: radius,
            stroke: new Stroke({
               color: strokeColor,
               width: strokeWidth,
            }),
         }),
      };
   } else {
      styleOptions = {};
   }

   return new Style(styleOptions);
}
