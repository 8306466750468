import $ from 'jquery';

import OLControl from 'ol/control/Control';

import ControlProperty from './ControlProperty';

/**
 * @classdesc
 * Abstract class of controls. Introduces utility methods to get/hide
 * the element.
 *
 * @extends {OLControl}
 */
class Control extends OLControl {
   /**
   * @param {import("ol/control/Control").Options=} opt_options Options
   */
   // eslint-disable-next-line
   constructor(opt_options) {
      super(opt_options);

      /**
     * TODO - this satisfies typecheck, but is pretty ugly...
     * @type {Element}
     * @protected
     */
      // eslint-disable-next-line
      this.element;

      /**
     * TODO - this satisfies typecheck, but is pretty ugly...
     * @type {Array.<import("ol/events").EventsKey>}
     * @protected
     */
      // eslint-disable-next-line
      this.listenerKeys;

      this.set(ControlProperty.ACTIVITY, 0);
   }

   // TODO - this satisfies typecheck, but is pretty ugly...
   /**
   * @inheritDoc
   */
   getMap() {
      return super.getMap();
   }

   /**
   * @inheritDoc
   */
   setMap(map) {
      super.setMap(map);
   }

   /**
   * @inheritDoc
   */
   get(key) {
      return super.get(key);
   }

   /**
   * @inheritDoc
   */
   set(key, value) {
      super.set(key, value);
   }

   /**
   * @return {Element} The control's element.
   */
   getElement() {
      return this.element;
   }

   /**
   * @return {!$} jQuery element.
   * @protected
   */
   $getElement() {
      return $(this.element);
   }

   /**
   * Hide the control.
   */
   hide() {
      this.$getElement().hide();
   }

   /**
   * Show the control.
   */
   show() {
      this.$getElement().show();
   }

   /**
   * Increment the 'activity' property by one.
   * @protected
   */
   registerActivity() {
      const activity = this.get(ControlProperty.ACTIVITY);
      this.set(ControlProperty.ACTIVITY, activity + 1);
   }
}

export default Control;
