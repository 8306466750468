import { applyTransform, containsXY, createEmpty, extendCoordinate, getCenter } from 'ol/extent';

import {
   getDisplay as featureGetDisplay,
} from './feature';
import { getTransform } from 'ol/proj';

/**
 * Fit view on coordinate or feature's center
 *
 * @typedef {Object} FitCenterOptions
 * @property {import("ol/coordinate").Coordinate=} coordinate Coordinate
 * @property {import("ol/Feature").default=} feature Feature
 * @property {import("ol/Map").default} map View
 */

const padding = [40, 10, 40, 10];

export function fitCenterBetweenTwoPoints(options) {
   const map = options.map;
   const view = map.getView();
   let extent = createEmpty();
   const feature1 = options.featurePoint1;
   let feature1Coordinates = '';
   const feature2 = options.featurePoint2;
   let feature2Coordinates = '';

   if (feature1 && featureGetDisplay(feature1[0])) {
      feature1Coordinates = feature1[0].getGeometry().getCoordinates();
      extendCoordinate(extent, feature1Coordinates);
   }

   if (feature2 && featureGetDisplay(feature2[0])) {
      feature2Coordinates = feature2[0].getGeometry().getCoordinates();
      extendCoordinate(extent, feature2Coordinates);
   }

   applyTransform(extent, getTransform('EPSG:4326', 'EPSG:3857'));

   if (feature1Coordinates && feature2Coordinates) {
      view.fit(extent, { padding });

      const mapExtent = map.getView().calculateExtent();
      if (!(containsXY(mapExtent, feature1Coordinates[0], feature1Coordinates[1]) && containsXY(mapExtent, feature2Coordinates[0], feature2Coordinates[1]))) {
         extent = createEmpty();
         extendCoordinate(extent, feature1Coordinates);
         fitCenterOnOnePoint(view, extent);
      }
   } else if (feature1Coordinates || feature2Coordinates) {
      fitCenterOnOnePoint(view, extent);
   }
}

function fitCenterOnOnePoint(view, extent) {
   const middleCoordinate = getCenter(extent);
   view.setCenter(middleCoordinate);
   view.setZoom(6);
}
