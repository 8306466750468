/**
 * @enum {string}
 */
export default {
   /**
   * Triggered when a feature has been clicked and had no popup shown.
   * @event SelectEvent#click
   * @api
   */
   CLICK: 'click',
};
