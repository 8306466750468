import {
   ActionTree,
   GetterTree,
   Module,
   MutationTree,
} from 'vuex';
import { NextServiceState, NextServiceStateInfo } from '@/types/nextServiceInfo';

import { RootState } from '@/store/types';
import { getPosition } from '@/api/nextService';

const state: NextServiceState = {
   info: null,
   isTrackingActive: false,
};

const getters: GetterTree<NextServiceState, RootState> = {
   trip: state => state.info?.result.trip,
   vehicleGeoJson: state => state.info?.result.vehicleGeoJson,
   originGeoJson: state => state.info?.result.originGeoJson,
   destinationGeoJson: state => state.info?.result.destinationGeoJson,
   typeVehicle: (state, getters) => getters.vehicleGeoJson?.features[0].properties.type,
   isTrackingActive: state => state.isTrackingActive,
};

const actions: ActionTree<NextServiceState, RootState> = {
   async getPosition({ commit, rootState }): Promise<void> {
      try {
         if (rootState.customerIdentifier) {
            const nextService: NextServiceStateInfo = await getPosition(rootState.customerIdentifier);
            commit('setNextService', nextService);
         }
      } catch {
         commit('setNextService', null);
      }
   },
   setIsTrackingActive({ commit }, isTrackingActive: boolean) {
      commit('setIsTrackingActive', isTrackingActive);
   },
};

const mutations: MutationTree<NextServiceState> = {
   setNextService(state, nextService: NextServiceStateInfo) {
      state.info = nextService;
   },
   setIsTrackingActive(state, isTrackingActive: boolean) {
      state.isTrackingActive = isTrackingActive;
   },
};

export const nextService: Module<NextServiceState, RootState> = {
   namespaced: true,
   state,
   getters,
   actions,
   mutations,
};
