import { Account, AccountState } from '@/types/authorization';
import {
   ActionTree,
   GetterTree,
   Module,
   MutationTree,
} from 'vuex';
import { getAccount, isAuthenticated } from '@/api/account';

import { RootState } from '@/store/types';
import store from '@/store/index';

export const state: AccountState = {
   isAuthenticated: false,
   account: undefined as unknown as Account,
};

export const getters: GetterTree<AccountState, RootState> = {
   isAuthenticated: state => state.isAuthenticated,
   account: state => state.account,
   customerId: (state, getters) => getters.account?.customerId,
   isAdmin: (state, getters) => getters.account?.isAdmin,
};

export const actions: ActionTree<AccountState, RootState> = {
   async getIsAuthenticated({ commit }): Promise<boolean> {
      const isAuth: boolean = await isAuthenticated();
      commit('setIsAuthenticated', isAuth);
      return isAuth;
   },
   async getAccount({ commit }): Promise<Account> {
      if (!state.account) {
         const account: Account = await getAccount();
         commit('setAccount', account);
         store.dispatch('setLocale', account.language);
         return account;
      } else {
         return state.account;
      }
   },
};

export const mutations: MutationTree<AccountState> = {
   setIsAuthenticated(state, result: boolean) {
      state.isAuthenticated = result;
   },
   setAccount(state, result: Account) {
      state.account = result;
   },
};

export const account: Module<AccountState, RootState> = {
   namespaced: true,
   state,
   getters,
   actions,
   mutations,
};
