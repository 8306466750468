import { Stroke, Style } from 'ol/style';

import MetroColor from '../Color';
import { rgb2rgba } from '../../color';
import {
   getOpacity as featureGetOpacity,
   getType as featureGetType,
} from '../../feature';
import FeatureType from '../../FeatureType';
import RESOLUTION from '../../RESOLUTION';
import { closestResolutionFloor } from '../../resolutions';

/**
 * @param {Feature} feature Feature.
 * @param {number} resolution Map view resolution.
 * @return {Style} Style
 */
const styleFunction = function (feature, resolution) {
   let strokeColor;

   const opacity = featureGetOpacity(feature);

   const type = featureGetType(feature);
   switch (type) {
      case FeatureType.METRO_LINE_1:
         strokeColor = rgb2rgba(MetroColor.LINE_1, opacity);
         break;
      case FeatureType.METRO_LINE_2:
         strokeColor = rgb2rgba(MetroColor.LINE_2, opacity);
         break;
      case FeatureType.METRO_LINE_4:
         strokeColor = rgb2rgba(MetroColor.LINE_4, opacity);
         break;
      case FeatureType.METRO_LINE_5:
         strokeColor = rgb2rgba(MetroColor.LINE_5, opacity);
         break;
      default:
         break;
   }

   let strokeWidth;

   const closestResolution = closestResolutionFloor(resolution);
   switch (closestResolution) {
      case RESOLUTION.R11:
         strokeWidth = 2;
         break;
      case RESOLUTION.R12:
         strokeWidth = 3;
         break;
      case RESOLUTION.R13:
         strokeWidth = 13;
         break;
      case RESOLUTION.R14:
         strokeWidth = 15;
         break;
      case RESOLUTION.R15:
      case RESOLUTION.R16:
         strokeWidth = 18;
         break;
      case RESOLUTION.R17:
      case RESOLUTION.R18:
         strokeWidth = 21;
         break;
      default:
         strokeWidth = 0;
         break;
   }

   return new Style({
      stroke: new Stroke({
         color: strokeColor,
         width: strokeWidth,
      }),
   });
};

export default styleFunction;
