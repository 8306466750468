import {
   getSelected as featureGetSelected,
   setSelected as featureSetSelected,
} from '../feature';
import PopupSimple from './Simple';

/**
 * @typedef {Object} PopupFeatureOptions
 * @property {!Feature} [feature] Feature bound to the popup.
 *
 * == stmap/popup/Simple ==
 * @property {Element|undefined} [content] Content of the popup.
 *
 * == stmap/Overlay ==
 * @property {string|undefined} [cls] Additionnal cls class.
 * @property {boolean|undefined} [closable] Whether the overlay can be
 *     closed or not. Defaults to `false`.
 *
 * == ol/Overlay ==
 * @property {number|string} [id] Set the overlay id. The overlay id can be used
 * with the {@link module:ol/Map~Map#getOverlayById} method.
 * @property {HTMLElement} [element] The overlay element.
 * @property {Array<number>} [offset=[0, 0]] Offsets in pixels used when positioning
 * the overlay. The first element in the
 * array is the horizontal offset. A positive value shifts the overlay right.
 * The second element in the array is the vertical offset. A positive value
 * shifts the overlay down.
 * @property {import("ol/coordinate").Coordinate} [position] The overlay position
 * in map projection.
 * @property {OverlayPositioning} [positioning='top-left'] Defines how
 * the overlay is actually positioned with respect to its `position` property.
 * Possible values are `'bottom-left'`, `'bottom-center'`, `'bottom-right'`,
 * `'center-left'`, `'center-center'`, `'center-right'`, `'top-left'`,
 * `'top-center'`, and `'top-right'`.
 * @property {boolean} [stopEvent=true] Whether event propagation to the map
 * viewport should be stopped. If `true` the overlay is placed in the same
 * container as that of the controls (CSS class name
 * `ol-overlaycontainer-stopevent`); if `false` it is placed in the container
 * with CSS class name specified by the `className` property.
 * @property {boolean} [insertFirst=true] Whether the overlay is inserted first
 * in the overlay container, or appended. If the overlay is placed in the same
 * container as that of the controls (see the `stopEvent` option) you will
 * probably set `insertFirst` to `true` so the overlay is displayed below the
 * controls.
 * @property {boolean} [autoPan=false] If set to `true` the map is panned when
 * calling `setPosition`, so that the overlay is entirely visible in the current
 * viewport.
 * @property {import("ol/Overlay").PanOptions} [autoPanAnimation] The
 * animation options used to pan the overlay into view. This animation is only
 * used when `autoPan` is enabled. A `duration` and `easing` may be provided to
 * customize the animation.
 * @property {number} [autoPanMargin=20] The margin (in pixels) between the
 * overlay and the borders of the map when autopanning.
 * @property {string} [className='ol-overlay-container ol-selectable'] CSS class
 * name.
 */

/**
 * @classdesc
 * Base class for map popups with very basic support:
 *
 * - a content
 * - being closed (shows a X button)
 *
 * @extends {PopupSimple}
 */
class PopupFeature extends PopupSimple {
   /**
   * @param {PopupFeatureOptions} options Options for the simple popup
   */
   constructor(options) {
      super({
         // stmap/popup/Simple
         content: options.content,
         // stmap/Overlay
         closable: options.closable,
         cls: options.cls,
         // ol/Overlay
         offset: options.offset,
         position: options.position,
         stopEvent: options.stopEvent,
         insertFirst: options.insertFirst,
         autoPan: options.autoPan,
         autoPanMargin: options.autoPanMargin,
         positioning: options.positioning,
      });

      /**
     * @type {!Feature}
     * @protected
     */
      this.feature = options.feature;
   }

   /**
   * @inheritDoc
   */
   close() {
      super.close();

      // Unselect feature, in case it was previously selected
      if (featureGetSelected(this.feature)) {
         featureSetSelected(this.feature, false);
      }
   }

   /**
   * @return {!Feature}
   */
   getFeature() {
      return this.feature;
   }
}

export default PopupFeature;
