import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tabbar" }
const _hoisted_2 = {
  class: "tabbar-list",
  role: "menubar"
}
const _hoisted_3 = { class: "tabbar-item" }
const _hoisted_4 = {
  class: "tabbar-item-icon icon-home",
  id: "home",
  name: "home"
}
const _hoisted_5 = { class: "tabbar-item-icon-label" }
const _hoisted_6 = {
  for: "home",
  class: "visuallyhidden"
}
const _hoisted_7 = { class: "visuallyhidden" }
const _hoisted_8 = { class: "tabbar-item" }
const _hoisted_9 = {
  class: "tabbar-item-icon icon-trip",
  name: "information"
}
const _hoisted_10 = { class: "tabbar-item-icon-label" }
const _hoisted_11 = {
  for: "information",
  class: "visuallyhidden"
}
const _hoisted_12 = { class: "visuallyhidden" }
const _hoisted_13 = {
  class: "button-link",
  disabled: ""
}
const _hoisted_14 = {
  class: "tabbar-item-icon icon-trip",
  name: "information"
}
const _hoisted_15 = { class: "tabbar-item-icon-label" }
const _hoisted_16 = { class: "tabbar-item" }
const _hoisted_17 = ["href"]
const _hoisted_18 = {
  class: "tabbar-item-icon icon-client-black-non-selected",
  name: "sirta"
}
const _hoisted_19 = { class: "tabbar-item-icon-label" }
const _hoisted_20 = { class: "tabbar-item" }
const _hoisted_21 = {
  class: "tabbar-item-icon icon-menu",
  name: "menuOptions"
}
const _hoisted_22 = {
  class: "tabbar-item-icon-label",
  "aria-label": "menu options"
}
const _hoisted_23 = {
  for: "menuOptions",
  class: "visuallyhidden"
}
const _hoisted_24 = { class: "visuallyhidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: { name: _ctx.Page.Home },
          alt: "",
          class: "tabbar-item-link",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setSelectedPage(_ctx.Page.Home)))
        }, {
          default: _withCtx(() => [
            _createElementVNode("i", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, [
                _createTextVNode(_toDisplayString(_ctx.$t("tabbar.home")) + " ", 1),
                _createElementVNode("label", _hoisted_6, [
                  _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.selectedMessage(_ctx.Page.Home)), 1)
                ])
              ])
            ])
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      _createElementVNode("li", _hoisted_8, [
        (!_ctx.disableInfoPage)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: { name: _ctx.Page.Information },
              alt: "",
              class: "'tabbar-item-link",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setSelectedPage(_ctx.Page.Information)))
            }, {
              default: _withCtx(() => [
                _createElementVNode("i", _hoisted_9, [
                  _createElementVNode("span", _hoisted_10, [
                    _createTextVNode(_toDisplayString(_ctx.$t("tabbar.information")) + " ", 1),
                    _createElementVNode("label", _hoisted_11, [
                      _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.selectedMessage(_ctx.Page.Information)), 1)
                    ])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["to"]))
          : (_openBlock(), _createBlock(_component_router_link, {
              key: 1,
              to: { name: _ctx.Page.Information },
              alt: "",
              class: "tabbar-item-link-disabled flex-center"
            }, {
              default: _withCtx(() => [
                _createElementVNode("button", _hoisted_13, [
                  _createElementVNode("i", _hoisted_14, [
                    _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t("tabbar.information")), 1)
                  ])
                ])
              ]),
              _: 1
            }, 8, ["to"]))
      ]),
      _createElementVNode("li", _hoisted_16, [
        _createElementVNode("a", {
          class: "tabbar-item-link",
          href: _ctx.sirtaURL
        }, [
          _createElementVNode("i", _hoisted_18, [
            _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t("tabbar.sirta")), 1)
          ])
        ], 8, _hoisted_17)
      ]),
      _createElementVNode("li", _hoisted_20, [
        _createVNode(_component_router_link, {
          to: { name: _ctx.Page.Menu },
          alt: "",
          class: "tabbar-item-link",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setSelectedPage(_ctx.Page.Menu)))
        }, {
          default: _withCtx(() => [
            _createElementVNode("i", _hoisted_21, [
              _createElementVNode("span", _hoisted_22, [
                _createTextVNode(_toDisplayString(_ctx.$t("tabbar.menu")) + " ", 1),
                _createElementVNode("label", _hoisted_23, [
                  _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.selectedMessage(_ctx.Page.Menu)), 1)
                ])
              ])
            ])
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ])
  ]))
}