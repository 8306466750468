import Property from './Property';

/**
 * To determine whether a bus line feature is primary or not, we check
 * the last character of the routeId. For example: "48E0" is primary
 * (0), "48E1" is not.
 *
 * When `routeId` property is not defined, then we're dealing with a
 * GeoJSON that doesn't return it for a purpose: all bus line features
 * should be considered and rendered as primary. This is the case for
 * the 'itinerary' page.
 *
 * @param {import("ol/Feature").default} feature Feature
 * @return {boolean} Whether the line is primary or not
 */
export function getIsPrimary(feature) {
   const routeId = getRouteId(feature);
   return routeId ? routeId.charAt(routeId.length - 1) === '0' : true;
}

/**
 * @param {import("ol/Feature").default} feature Feature
 * @return {string} Line + direction + index of route
 */
export function getRouteId(feature) {
   return /** @type {string} */ (feature.get(Property.ROUTE_ID));
}
