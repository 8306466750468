
export interface Status {
   level: string;
   message: string;
}

export interface Geometry {
   type: string;
   coordinates: number[];
}

export interface PropertyVehicle {
   currentStreet: string;
   nearStreetIntersection: string;
   type: string;
   display: boolean;
   distanceToOriginDestination: number;
}

export interface NextServiceInfoFeatureVehicle {
   type: string;
   geometry: Geometry;
   properties: PropertyVehicle;
}

export interface NextServiceInfoVehicle {
   type: string;
   features: NextServiceInfoFeatureVehicle[]
}

export interface PropertyOriginDestination {
   vehicleStatus: string;
   activityNext: boolean;
   eta: string;
   address: string;
   display: boolean;
}

export interface NextServiceInfoFeatureOriginDestination {
   type: string;
   geometry: Geometry;
   properties: PropertyOriginDestination;
}

export interface NextServiceInfoOriginDestination {
   type: string;
   features: NextServiceInfoFeatureOriginDestination[];
}

export enum WarningMessageId {
   Undefined = 'undefined',
   ConnectionLost = 'connectionLost',
   Waiting = 'waiting',
   UpcommingPickup = 'upcommingPickup',
   NoTrip = 'noTrip',
}

export interface WarningMessage {
   id: WarningMessageId;
   message: string;
}

export interface NextServiceInfoTrip {
   tripServiceStatus: string;
   scheduleIdentifier: string;
   customerIdentifier: number;
   tripIdentifier: string;
   earliestPickUpTime: string;
   latestPickUpTime: string;
   warningMessage: WarningMessage | null;
   tripMessage: string;
   vehicleCoordinates: number[];
   originCoordinates: number[];
   destinationCoordinates: number[];
}

export interface NextServiceInfoResult {
   vehicleGeoJson: NextServiceInfoVehicle;
   originGeoJson: NextServiceInfoOriginDestination;
   destinationGeoJson: NextServiceInfoOriginDestination;
   trip: NextServiceInfoTrip;
}

export interface NextServiceStateInfo {
   type: string;
   result: NextServiceInfoResult;
   status: Status;
}

export interface NextServiceState {
   info: NextServiceStateInfo | null;
   isTrackingActive: boolean;
}
