
import ControlToggleLayer from '../../control/ToggleLayer';

/**
 * @classdesc
 * A button control that toggles the busPosition layer visibility
 *
 * @extends {ControlToggleLayer}
 */
class BusPositionControl extends ControlToggleLayer {
   /**
   * @param {import("../../control/ToggleLayer").ToggleLayerOptions} options Options
   */
   constructor(options) {
      super(options);

      const cls = 'stmap-control-busposition';
      const iconCls = 'icon-bus';

      this.$getElement().addClass(`${cls} ${iconCls}`);

      this.clsActive = `${cls}-active`;
   }
}

export default BusPositionControl;
