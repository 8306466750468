/**
 * @enum {import('ol/color').Color}
 */
export default {
   /**
   * primary-cyan #009ee0
   * Used for: bus line, bus stop
   */
   LINE: [0, 158, 224],
};
