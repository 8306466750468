import ControlButton from '../control/Button';

/**
 * @classdesc
 * Abtract class for button controls.
 *
 * @extends {ControlButton}
 */
class ProximityControlDestination extends ControlButton {
   /**
   * @param {import("ol/control/Control").Options} options Options
   */
   constructor(options) {
      super(options);
      const cls = 'stmap-control-proximity-destination';
      const clsActive = 'stmap-control-proximity-destination-active';
      const iconCls = 'icon-path-non-selected';
      this.$getElement().addClass(`${cls} ${iconCls} ${clsActive}`);
      this.btnActive = true;
   }

   /**
   * @inheritDoc
   */
   handleButtonClick(evt) {
      const cls = 'stmap-control-proximity-destination-active';
      if (!this.btnActive) {
         this.$getElement().addClass(`${cls}`);
      } else {
         this.$getElement().removeClass(`${cls}`);
      }
      this.btnActive = !this.btnActive;
      super.handleButtonClick(evt);
      this.registerActivity();
   }

   /**
* @inheritDoc
*/
   disable(status) {
      const clsActive = 'stmap-control-proximity-destination-active';
      const clsDisable = 'stmap-control-proximity-destination-disable';
      if (status) {
         this.$getElement().removeClass(`${clsActive}`);
         this.$getElement().addClass(`${clsDisable}`);
      } else {
         this.$getElement().addClass(`${clsActive}`);
         this.$getElement().removeClass(`${clsDisable}`);
      }
   }

   /**
   * @inheritDoc
   */
   setStatus(status) {
      const cls = 'stmap-control-proximity-destination-active';
      this.btnActive = status;
      if (status) {
         this.$getElement().addClass(`${cls}`);
      } else {
         this.$getElement().removeClass(`${cls}`);
      }
   }
}

export default ProximityControlDestination;
