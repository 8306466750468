import {
   GetterTree,
   Module,
} from 'vuex';

import { RootState } from '@/store/types';
import english from './links/en-CA.json';
import french from './links/fr-CA.json';

const state = {
   'fr-CA': french,
   'en-CA': english,
};

const getters: GetterTree<any, RootState> = {
   firstTabs: (state, getters, rootState, rootGetters) => state[rootGetters.locale].resultFirstNav,
   secondTabs: (state, getters, rootState, rootGetters) => state[rootGetters.locale].resultSecondNav,
};

export const menu: Module<any, RootState> = {
   namespaced: true,
   state,
   getters,
};
