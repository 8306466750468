export default {
   /**
   * Metro line
   */
   METRO_LINE_1: 1,
   METRO_LINE_2: 2,
   METRO_LINE_4: 4,
   METRO_LINE_5: 5,
   /**
   * Metro station
   */
   METRO_STATION_JUNCTION: 10,
   METRO_STATION_1: 11,
   METRO_STATION_2: 12,
   METRO_STATION_4: 14,
   METRO_STATION_5: 15,
   /**
   * Bus
   */
   BUS_LINE: 6,
   BUS_STOP: 16,
   /**
   * Walking
   */
   WALKING_LINE: 7,
   WALKING_POINT: 17,
   /**
   * Train
   */
   TRAIN_LINE: 8,
   TRAIN_POINT: 18,
   /**
   * Itinerary origin/destination
   */
   ITINERARY_ORIGIN: 21,
   ITINERARY_DESTINATION: 22,
};
