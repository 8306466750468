import '@/styles.scss';
import { createApp } from 'vue';
import App from './app.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import VueGtag from 'vue-gtag';
import mapBrowser, { ModeHomeOptions } from '@/plugins/stmap/MapBrowser';
import {
   FocusDirective,
} from './directives';

mapBrowser.modeEnableHome(<ModeHomeOptions>{});
createApp(App)
   .use(i18n)
   .use(store)
   .use(router)
   .use(VueGtag, {
      config: {
         id: 'UA-37735918-11',
      },
   }, router)
   .directive('focus', FocusDirective)
   .mount('#app');
