export { default as busLineStyleFunction } from './bus/line/styleFunction';
export {
   default as busPositionStyleFunction,
} from './bus/position/styleFunction';
export { default as busStopStyleFunction } from './bus/stop/styleFunction';
export { default as metroLineStyleFunction } from './metro/line/styleFunction';
export {
   default as metroStationStyleFunction,
} from './metro/station/styleFunction';

export const ICON_SCALE = 1.2;
