import { Fill, Style, Text as TextStyle, Circle as CircleStyle } from 'ol/style';
import { get as getProximityRadius } from './radius';

export default function (feature, resolution) {
   if (feature.get('hidden')) {
      return [];
   }

   const circleRadius = getProximityRadius();

   const styles = [
      new Style({
         text: new TextStyle({
            font: "24px 'icomoon'",
            text: '\ue917',
            fill: new Fill({ color: feature.get('color') || '#000' }),
         }),
      }),
   ];

   if (feature.get('active')) {
      const radius = circleRadius / resolution;
      styles.splice(
         0,
         0,
         new Style({
            image: new CircleStyle({
               radius,
               fill: new Fill({ color: [0, 0, 0, 0.08] }),
            }),
         }),
      );
   }

   return styles;
}
