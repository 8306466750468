import { WarningMessage, WarningMessageId } from '@/types/nextServiceInfo';

import { Page } from '@/router';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

export default defineComponent({
   name: 'Notification',
   data() {
      return {
         WarningMessageId, // I had to add this bit
      };
   },
   computed: {
      ...mapGetters('nextService', [
         'trip',
      ]),
      ...mapGetters([
         'serviceError',
      ]),
      warningMessage(): WarningMessage {
         if (this.serviceError) {
            return this.serviceError;
         }
         return this.trip?.warningMessage;
      },
      message(): string {
         return this.warningMessage.message;
      },
      id(): WarningMessageId {
         return this.warningMessage.id;
      },
      display(): boolean {
         return this.$route.name === Page.Home;
      },
   },
});
