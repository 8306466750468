/**
 * Hash of resolutions
 * @enum {number}
 */
export default {
   R11: 76.43702828517624,
   R12: 38.21851414258813,
   R13: 19.10925707129406,
   R14: 9.554628535647032,
   R15: 4.777314267823516,
   R16: 2.388657133911758,
   R17: 1.194328566955879,
   R18: 0.5971642834779395,
};
